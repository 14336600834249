/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from "react";
import * as React from 'react';
import moment from 'moment';
import AppBar from "../../components/AppBar";
import { baseUrl } from "../../connections";

import { useGetProfileScreenStyles } from "./index.styles";
import { useLogout } from "../../hooks/services/uselogout";
import TextBox from "../../components/TextBox";
import Snackbar from "../../components/Snackbar";
import uuid from "react-uuid";
import Button from "../../components/Button";
import BackButton from "../../components/BackButton";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import Avatar from "../../components/Avatar";
import { useGetNavItems } from "../Home/appbarNav";
import { ssnFormat, zipFormat, getBase64, IdProofFormat } from "../../utils"
import { useStateData } from "../../hooks/services/useStateData";
import { useCountyData } from "../../hooks/services/useCountyData";
import DeleteIcon from '@mui/icons-material/Delete';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { useTheme } from '@material-ui/core/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import setAccountDeletePayloads
// import {setAccountDeletePayloads} from "../../hooks/services/useAccountDelete"
import { useAccountDelete } from "../../hooks/services/useAccountDelete";
import { useCityData } from "../../hooks/services/useCityData";
import { COUNTRY } from "../../constants"
// import AppFooter from "../../components/AppFooter";
import Footer1 from "../../components/Footer/Footer";
import axios from 'axios';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Delete } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useGetBackButtonStyles } from "../../components/BackButton/index.styles";


const Profile = () => {
  const size = 88
  const styles = useGetBackButtonStyles({ size })
  const classes = useGetProfileScreenStyles();
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const { setPayloadsLogout } = useLogout();
  const [isDisabled, setIsDisabled] = useState(true);
  const [selCountry, setSelCountry] = useState();
  const [selState, setSelState] = useState();
  const [selCounty, setSelCounty] = useState();
  const [selCity, setSelCity] = useState();
  const { stateData, setStateData } = useStateData();
  const { countyData, setCountyData } = useCountyData();
  const { cityData, setCityData } = useCityData();
  const [errortoast, seterrortoast] = useState(false)
  const [success, setsuccess] = useState(false)
  // const { setAccountDeletePayloads } = useAccountDelete();
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setStateData(userDetails?.address?.country);
    setCountyData(userDetails?.address?.state);
    setCityData(userDetails?.address?.county);
  }, [userDetails])

  useEffect(() => {
    if (userDetails) {
      const country = COUNTRY.find((val) => userDetails?.address?.country == val.value);
      setSelCountry(country?.key);
    }
  }, [userDetails])

  useEffect(() => {
    if (stateData) {
      const state = stateData.find((val) => userDetails?.address?.state === val.uuid);
      setSelState(state?.name);
    }
  }, [stateData])

  useEffect(() => {
    if (countyData) {
      const county = countyData.find((val) => userDetails?.address?.county === val.uuid);
      setSelCounty(county?.name);
    }
  }, [countyData])

  useEffect(() => {
    if (cityData) {
      const city = cityData.find((val) => userDetails?.address?.city === val.uuid);
      setSelCity(city?.name);
    }
  }, [cityData])
  const [interests, setinterests] = useState([])
  // const [open ,setopen ] = useState(false)

  // const HandleDelete = (uuid) => {
  //   console.log(uuid, "uuiduuid545344")
  //   // setAccountDeletePayloads(uuid)
  //   // alert('hsff45645')
  //   // setAccountDeletePayloads(uuid)

  // }
  const handlelogout = () => {
    setPayloadsLogout({
      user_uuid: userDetails?.user_uuid
    })
    setTimeout(() => {
      navigate("/login")
      // logoutHandler()
    }, 1000);
  }
  function MyComponent() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return <Dialog fullScreen={fullScreen} />;
  }

  const HandleDelete = (id) => {
    axios.delete(`${baseUrl}/user/delete/account/${userDetails?.user_uuid}`, {
      headers: {
        "x-auth-token": userDetails?.token
      }
    })
      .then((res) => {
        setTimeout(() => {
          handleClose()
          handlelogout()
        }, 1000);
        setsuccess(true)

      })
      .catch((err) => {
        seterrortoast(true)
        // <Snackbar/>
        // toast.error(err.response.data)
      })
  }
  
  //   useEffect(() => {
  //     if (userDetails?.intrests) {
  //         const optionslanguage = luserDetails?.intrests?.map((item) => ({
  //             value: item,
  //             label: item
  //         }));
  //         setinterests(optionslanguage);
  //     }

  // }, [userDetails]);
  
  const backhandle =()=>{
    navigate("/home")
  }


  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this account?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* "Are you sure you want to delete this account?" */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={HandleDelete} >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* </React.Fragment> */}
      <div>
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        ></Box>
        <AppBar navItems={useGetNavItems({})} />
        <div css={classes.titleBanner}>
          {/* <IconButton aria-label="back" size="small" css={styles.backArrowpopup} onClick={backhandle}>
            <ArrowBackIosIcon />Back
          </IconButton> */}
           <div style={{ justifyContent: 'flex-end' }}><BackButton /></div>
          <div ><h1>My Account Details</h1></div>
          <div></div>
        </div>
        <div css={classes.signUpFormContainer}>
          <div css={classes.signupForm}>
            <Avatar src={userDetails.avatar} flatStyle rounded size={120} />
            <div css={classes.formFieldsWrapper}>
              <div css={classes.formFieldsGroup}>
                <div css={classes.formFieldsFlexGroup}>
                  {/* <label>First Name</label> */}
                  <TextField
                    //  id="outlined-basic" 
                    label="First Name"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    readonly
                    disabled={isDisabled}
                    value={userDetails?.firstName}
                    id="firstName"
                  />
                  {/* <TextBox
                  variant="outline"
                  label="First Name"
                  color="secondary"
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  value={userDetails?.firstName}
                  id="firstName"
                  placeholder="First Name"
                /> */}
                  <TextField
                    variant="outlined"
                    label="Last Name"
                    color="secondary"
                    fullWidth
                    readonly
                    disabled={isDisabled}
                    value={userDetails?.lastName}
                    id="lastName"
                    placeholder="Last Name"
                  />
                  {/* <TextBox
                  variant="outline"
                  label="Last Name"
                  color="secondary"
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  value={userDetails?.lastName}
                  id="lastName"
                  placeholder="Last Name"
                /> */}
                </div>
                <div css={classes.formFieldsFlexGroup}>
                  {userDetails?.phone?.code &&
                    <TextField
                      variant="outlined"
                      label="Phone"
                      color="secondary"
                      type="tel"
                      fullWidth
                      readonly
                      disabled={isDisabled}
                      defaultCountryCode={userDetails?.phone?.code}
                      value={userDetails?.phone?.value}
                      id="phone"
                    />
                    // <TextBox
                    //   variant="outline"
                    //   label="Phone"
                    //   color="secondary"
                    //   type="tel"
                    //   fullWidth
                    //   readonly
                    //   disabled={isDisabled}
                    //   defaultCountryCode={userDetails?.phone?.code}
                    //   value={userDetails?.phone?.value}
                    //   id="phone"
                    // />
                  }
                  <TextField
                    variant="outlined"
                    label="Primary Email"
                    color="secondary"
                    type="email"
                    value={userDetails?.primaryEmail}
                    fullWidth
                    readonly
                    disabled={isDisabled}
                    id="primaryEmail"
                  />
                  {/* <TextBox
                  variant="outline"
                  label="Primary Email"
                  color="secondary"
                  type="email"
                  value={userDetails?.primaryEmail}
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  id="primaryEmail"
                /> */}
                </div>
                <div css={classes.formFieldsFlexGroup}>
                  <TextField
                    variant="outlined"
                    label="Address1"
                    color="secondary"
                    fullWidth
                    readonly
                    disabled={isDisabled}
                    value={userDetails?.address?.location}
                    id="location"
                  />
                  {/* <TextBox
                  variant="outline"
                  label="address1"
                  color="secondary"
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  value={userDetails?.address?.location}
                  id="location"
                /> */}
                  <TextField
                    variant="outlined"
                    label="Address2"
                    color="secondary"
                    fullWidth
                    readonly
                    disabled={isDisabled}
                    value={userDetails?.address?.location}
                    id="addressLine1"
                  />
                  {/* <TextBox
                  variant="outline"
                  label="Address2"
                  color="secondary"
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  value={userDetails?.address?.addressLine1}
                  id="addressLine1"
                /> */}
                </div>
                <div css={classes.formFieldsFlexGroup}>
                  <TextField
                    variant="outlined"
                    label="Country"
                    color="secondary"
                    fullWidth
                    readonly
                    disabled={isDisabled}
                    value={selCountry || userDetails?.address?.country}
                    id="country"
                  />
                  {/* <TextBox
                  variant="outline"
                  label="Country"
                  color="secondary"
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  value={selCountry || userDetails?.address?.country}
                  id="country"
                /> */}
                  <TextField
                    variant="outlined"
                    label="State"
                    color="secondary"
                    fullWidth
                    readonly
                    disabled={isDisabled}
                    id="state"
                    value={selState || userDetails?.address?.state}
                  />
                  {/* <TextBox
                  variant="outline"
                  label="State"
                  color="secondary"
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  id="state"
                  value={selState || userDetails?.address?.state}
                /> */}
                </div>
                <div css={classes.formFieldsFlexGroup}>
                  <TextField
                    variant="outlined"
                    label="County"
                    color="secondary"
                    fullWidth
                    readonly
                    disabled={isDisabled}
                    value={selCounty || userDetails?.address?.county}
                    id="county"
                  />
                  {/* <TextBox
                  variant="outline"
                  label="County"
                  color="secondary"
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  value={selCounty || userDetails?.address?.county}
                  id="county"
                /> */}
                  <TextField
                    variant="outlined"
                    label="City"
                    color="secondary"
                    fullWidth
                    readonly
                    disabled={isDisabled}
                    id="city"
                    value={selCity || userDetails?.address?.city}
                  />
                  {/* <TextBox
                  variant="outline"
                  label="City"
                  color="secondary"
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  id="city"
                  value={selCity || userDetails?.address?.city}
                /> */}
                </div>
                <div css={classes.formFieldsFlexGroup}>
                  <TextField
                    variant="outlined"
                    label="House No"
                    color="secondary"
                    fullWidth
                    readonly
                    disabled={isDisabled}
                    value={userDetails?.address?.apartmentNo}
                    id="apartmentNo"
                  />
                  {/* <TextBox
                  variant="outline"
                  label="House no"
                  color="secondary"
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  value={userDetails?.address?.apartmentNo}
                  id="apartmentNo"
                /> */}
                  <TextField
                    variant="outlined"
                    label="ZIP Code"
                    color="secondary"
                    fullWidth
                    readonly
                    disabled={isDisabled}
                    type="text"
                    // value={zipFormat(userDetails?.address?.zip)}
                    value={userDetails?.address?.zip}
                    id="zip"
                  />
                  {/* <TextBox
                  variant="outline"
                  label="Zip"
                  color="secondary"
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  type="text"
                  value={zipFormat(userDetails?.address?.zip)}
                  id="zip"
                /> */}
                </div>

                <div css={classes.formFieldsFlexGroup}>
                  <TextField
                    variant="outlined"
                    label="Race/Ethnicity"
                    color="secondary"
                    fullWidth
                    readonly
                    disabled={isDisabled}
                    value={userDetails?.demographics?.race}
                    id="race"
                  />
                  {/* <TextBox
                  variant="outline"
                  label="Race/Ethnicity"
                  color="secondary"
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  value={userDetails?.demographics?.race}
                  id="race"
                /> */}
                  <TextField
                    variant="outlined"
                    label="DOB"
                    color="secondary"
                    fullWidth
                    readonly
                    disabled={isDisabled}
                    type="date"
                    value={moment(userDetails?.demographics?.dob).format('YYYY-MM-DD')}
                    id="dob"
                  />
                  {/* <TextBox
                  variant="outline"
                  label="DOB"
                  color="secondary"
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  type="date"
                  value={moment(userDetails?.demographics?.dob).format('YYYY-MM-DD')}
                  id="dob"
                /> */}
                </div>
                <div css={classes.formFieldsFlexGroup}>
                  <TextField
                    variant="outlined"
                    label="Interests"
                    color="secondary"
                    fullWidth
                    readonly
                    disabled={isDisabled}
                    // value={interests}
                    value={userDetails?.intrests ? userDetails.intrests.join(', ') : ''}
                    id="race"
                  />
                  {/* <TextBox
                  variant="outline"
                  label="Race/Ethnicity"
                  color="secondary"
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  value={userDetails?.demographics?.race}
                  id="race"
                /> */}
                  <TextField
                    variant="outlined"
                    label="Languages"
                    color="secondary"
                    fullWidth
                    readonly
                    disabled={isDisabled}
                    // value={languages}
                    value={userDetails?.languages ? userDetails.languages.join(', ') : ''}

                    id="race"
                  />
                  {/* <TextBox
                  variant="outline"
                  label="DOB"
                  color="secondary"
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  type="date"
                  value={moment(userDetails?.demographics?.dob).format('YYYY-MM-DD')}
                  id="dob"
                /> */}
                </div>
                <TextField
                  variant="outlined"
                  label="Religion"
                  color="secondary"
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  value={userDetails?.religion}
                  id="landmark"
                />
                <TextField
                  variant="outlined"
                  label="Landmark/Housing Community"
                  color="secondary"
                  fullWidth
                  readonly
                  disabled={isDisabled}
                  value={userDetails?.address?.landmark}
                  id="landmark"
                />
                {/* <TextBox
                variant="outline"
                label="Landmark/Housing community"
                color="secondary"
                fullWidth
                readonly
                disabled={isDisabled}
                value={userDetails?.address?.landmark}
                id="landmark"
              /> */}
              </div>
              {/* <div css={classes.footerBtn}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                sx={{
                  minWidth: 120,
                  maxHeight: 45,
                  marginLeft: "auto",
                  mt: 3,
                }}
                onClick={() => setIsDisabled(!isDisabled)}
              >
                Edit
              </Button>
            </div> */}
            </div>
            {/* <div> */}
            {/* <Button onClick={()=>HandleDelete(userDetails)}><Delete></Delete> </Button> */}
            {/* <Button variant="outlined" onClick={() => handleClickOpen()} startIcon={<DeleteIcon />}>
              Delete
            </Button> */}
            {/* </div> */}
          </div>
        </div>
        {/* <AppFooter /> */}
        <Footer1 />
      </div>
      {/* {errortoast === true &&
        <Snackbar key={uuid()} message="Something went Wrong!" isOpen={errortoast} />
      }
      {success === true &&
        <Snackbar key={uuid()} message={"Account deleted successfully!"} isOpen={success} severity={'success'} />
      } */}
      {/* errortoast */}
    </>
  );
};

export default Profile;
