/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  ThemeProvider,
  TextField,
  Autocomplete
} from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { theme } from "./index.styles";
import uuid from "react-uuid";

const Dropdown = ({
  label,
  lists,
  renderKey,
  renderValue,
  onChange,
  id,
  value = '',
  component = "dropdown",
  countLabel = false,
  mails_count,
  ...props
}) => {
  const [val, setVal] = useState();

  useEffect(()=>{
    setVal(value);
  },[value])

  const handleChange = (event, value) => {
    const newValue = component === 'dropdown' ? event.target.value: value;
    setVal(newValue);
    onChange(event, newValue);
  };

  const getLabel = (value) => {
    const obj = lists.find(val => val?.[renderValue] === value);
    const namewithCount = obj?.name + `(${obj?.mails_count})` || '';
    const onlyName = obj?.name || ''
    return countLabel ? namewithCount : onlyName;
  }

  if(id === "country"){
    const name = getLabel(value)
  }
  return (
    <ThemeProvider theme={theme}>
      {component === "dropdown" ? (
        <FormControl fullWidth>
          <InputLabel id="simple-select">{label}</InputLabel>
          <Select
            labelId="simple-select"
            id={id || uuid()}
            value={val}
            defaultValue={val}
            label={label}
            onChange={handleChange}
          >
            {lists &&
              lists.map((list, i) => (
                <MenuItem key={i} value={list?.[renderValue]}>
                  {list?.[renderKey]}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      ) : (
        <>
        <Autocomplete
          id={id || uuid()}
          options={lists}
          getOptionLabel={(option) => option?.[renderKey] || getLabel(val)}

          // getOptionLabel={(option) => countLabel ? (option?.[renderKey] + `(${option?.[mails_count]})`) || getLabel(val) : option?.[renderKey] || getLabel(val)}
          getOptionSelected={(option, value) => option?.[renderValue] === value}
          fullWidth
          value={getLabel(val)}
          sx={{margin: 0, padding: '0'}}
          onChange={(event, newValue) => handleChange(event, newValue?.[renderValue])}
          renderInput={(params) => (
            <TextField {...params} label={label} margin="normal" />
          )}
          renderOption={(props, option, { inputValue = val }) => {
            const matches = match(option?.[renderValue], inputValue, {
              insideWords: true,
            });
            const parts = parse(option?.[renderKey], matches);
            return (
              <li {...props} key={uuid()}>
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
        /></>
      )}
    </ThemeProvider>
  );
};

export default Dropdown;
