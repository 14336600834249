/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import SignInTemplate from "../../templates/SignIn";
import TextBox from "../../components/TextBox";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useGetResetFormStyles } from "./index.styles";
import { Typography } from "@mui/material";
import { usePassword } from "../../hooks/services/usePassword";
import { IDENTITYTYPE } from "../../constants"
import { IdProofFormat } from "../../utils"
import Snackbar from "../../components/Snackbar";
import { Snackbar as MuiSnackbar, ThemeProvider } from "@mui/material";
import Alert from '@mui/material/Alert';
import { theme } from "../../components/Snackbar/index.styles";
import Previous from '../../assets/images/previous.png';
import moneymailslogo from "../../../src/assets/images/svg/money-mails-logo.f4f6aef6.png";


const ResetPassword = () => {
  const { verifyData, updateResult, updateResult1, updateResult2, passError, isRequest, error, message, setMobilePayload, setResetPayload, isPassLoading } = usePassword();
  const [mobile, setMobile] = useState();
  const [identityNumber, setIdentityNumber] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const classes = useGetResetFormStyles();
  const [identityLable, setIdentityLable] = useState();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [errormessage, setErrorMessage] = useState('');
  const [errormessage1, setErrorMessage1] = useState('');


  const onSubmitHandler = () => {

    if (!mobile?.value) {
      setErrorMessage('Please Enter Phone Number');
      setOpen(true)
      return;
    }
    if (mobile?.value === "") return;
    const payload = {
      mobile: mobile.code + mobile.value
    }
    setMobilePayload(payload);
    setOpen(true);
  }

  const submitHandler = (e) => {
    e.preventDefault();

    if (newPwd !== confirmPwd) {
      setErrorMessage1('New Password and Confirm Password Must be Same');
      setOpen1(true)
      return;
    }

    if (newPwd === "") {
      setErrorMessage1('Please Enter a New Password and Confirm Password');
      setOpen1(true)
      return;
    }

    if (identityNumber === "") {
      setErrorMessage1('Please Enter a Driving Licence');
      setOpen1(true)
      return;
    }

    // if (newPwd === "" || confirmPwd === "") return;
    // if (newPwd !== confirmPwd) return;
    // if (identityNumber === "") return;
    const payload = {
      mobile: mobile.code + mobile.value,
      password: newPwd,
      id_type: verifyData?.data,
      id_number: identityNumber.replace(/-/g, '')
    }
    setResetPayload(payload);
  }

  useEffect(() => {
    if (verifyData?.data) {
      const lable = IDENTITYTYPE.find(item => item.value === verifyData?.data);
      setIdentityLable(lable?.key);
    }
  }, [verifyData])

  useEffect(() => {
    setTimeout(() => {
      if (updateResult) navigate('/')
    }, 2000)
  }, [updateResult])

  const Goback = () => {
    navigate(-1)
  }


  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 767
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [])

  return (
    <SignInTemplate>
      <div css={classes.container}>
        <div>
        {mobileView &&
            <div>
              <img src={moneymailslogo} style={{ width: '90%' ,marginBottom:'10%'}} alt="illustration" />
            </div>
          }
          {mobileView &&
            <>
              {!verifyData?.success && <div onClick={Goback} style={{ marginLeft: "-13%", marginBottom: "4%" }}><img style={{ height: "25px", cursor: "pointer" }} src={Previous} alt="" />&nbsp;  Back</div>}
            </>
          }
          {!mobileView &&
            <>
              {!verifyData?.success && <div onClick={Goback} style={{ marginLeft: "-100px", marginBottom: "-32px" }}><img style={{ height: "25px", cursor: "pointer" }} src={Previous} alt="" />&nbsp;  Back</div>}
            </>
          }
         
          <h2 css={classes.heading}>Reset Password</h2>
          {!verifyData?.success ? (<Typography css={classes.subText}>
            {/* Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text */}
            If you've forgotten your password, don't worry. Kindly furnish your registered mobile number,
            enabling us to show instructions for resetting your password.
          </Typography>)
            :
            (<Typography css={classes.subText}>
              {/* Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text */}
              To begin the password reset process, please furnish your driving license and
              proceed to generate a new password, confirming it once more to guarantee precision.
            </Typography>)
          }

        </div>
        {!verifyData?.success ? (<><form css={classes.formContainer} onSubmit={(e) => e.preventDefault()}>
          <TextBox
            label="Phone Number"
            type="tel"
            value={mobile?.value || ""}
            defaultCountryCode="1"
            onChange={(_e, val) => setMobile(val)}
          />
        </form>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            color="secondary"
            onClick={onSubmitHandler}
          >
            <div css={classes.buttonContainer}>
              <span>Send Instructions</span>
              <ArrowForwardIcon />
            </div>
          </Button>
          <Button
            type="button"
            variant="text"
            fullWidth
            color="secondary"
            sx={{ marginTop: "80px" }}
            onClick={() => navigate("/signup")}
          >
            Don't have an account? Sign Up Here
          </Button></>) : (<>
            <form css={classes.formContainer} onSubmit={(e) => e.preventDefault()}>
              <TextBox
                type="text"
                // label={identityLable}
                label="Driving Licence/Voter ID"
                value={IdProofFormat(identityNumber) || ""}
                key="id_number"
                onChange={(_e, val) => setIdentityNumber(val)}
              />
              <TextBox
                type="password"
                label="New Password"
                value={newPwd}
                onChange={(_e, val) => setNewPwd(val)}
              // validationFn={validatePasswords}
              />
              <TextBox
                type="password"
                label="Confirm Password"
                onChange={(_e, val) => setConfirmPwd(val)}
                value={confirmPwd}
                // validationFn={validatePasswords}
                validationFn={() =>
                  newPwd === confirmPwd ||
                  "Passwords doesn't match"
                }
              />
            </form>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              color="secondary"
              onClick={submitHandler}
            >
              <div css={classes.buttonContainer}>
                <span>Submit</span>
                <ArrowForwardIcon />
              </div>
            </Button>
          </>
        )}
        <ThemeProvider theme={theme}>
          <MuiSnackbar open={open} autoHideDuration={3000} onClose={() => setOpen(false)} anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
            <Alert severity={!updateResult1 ? 'error' : 'success'}>
              {message || errormessage}
            </Alert>
          </MuiSnackbar>
        </ThemeProvider>

        <ThemeProvider theme={theme}>
          <MuiSnackbar open={open1} autoHideDuration={3000} onClose={() => setOpen1(false)} anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
            <Alert severity={!updateResult2 ? "error" : "success"}>
              {!updateResult2 && errormessage1}
            </Alert>
          </MuiSnackbar>
        </ThemeProvider>
        {isRequest && <Snackbar message={updateResult ? 'Your password has been reset successfully. Please proceed to login now' : passError} isOpen={true} severity={!updateResult ? 'error' : 'success'} />}
      </div>
    </SignInTemplate>
  );
};

export default ResetPassword;

