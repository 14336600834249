/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { Radio } from "antd";
import BrandIconCard from "../../components/BrandIconCard";
import ReebokSVG from "../../icons/reebok";
import AdidasSvg from "../../icons/adidas";
import NikeSVG from "../../icons/nike";
import GroceriesSvg from "../../icons/groceries";
import FoodSvg from "../../icons/food";
import HospitalSvg from "../../icons/hospital";
import DeliverySvg from "../../icons/delivery";
import uuid from "react-uuid";
import { SearchOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useGetBackButtonStyles } from "../../components/BackButton/index.styles";

const colorsForBrands = ["#F2FCFF", "#F9FFF2", "#FFF2FB", "#FFEBDC"];
const brandSvgs = [
  {
    icn: NikeSVG,
    text: "Nike",
  },
  {
    icn: ReebokSVG,
    text: "Reebok",
  },
  {
    icn: AdidasSvg,
    text: "Adidas",
  },
];
const colorsForCategories = [
  "#EEF8FF",
  "#EEFFF9",
  "#FEEEFF",
  "#FFEEF3",
  "#FFFDEE",
  "#FFF4EE",
];
const colorsFormails = [
  "#EEF8FF",
  "#EEFFF9",
  "#FEEEFF",
  "#FFEEF3",
  "#FFFDEE",
  "#FFF4EE",
];
const categoriesSvgs = [
  {
    icn: GroceriesSvg,
    text: "Grocerys",
  },
  {
    icn: FoodSvg,
    text: "Food",
  },
  {
    icn: HospitalSvg,
    text: "Hospitals",
  },
  {
    icn: DeliverySvg,
    text: "Courier",
  },
];

const mailsSvgs = [
  {
    icn: GroceriesSvg,
    text: "Grocerys",
  },
  {
    icn: FoodSvg,
    text: "Food",
  },
  {
    icn: HospitalSvg,
    text: "Hospitals",
  },
  {
    icn: DeliverySvg,
    text: "Courier",
  },
];
const arr = new Array(14).fill("").map((item, idx) => idx);

const DrawerSearchContent = ({ classes, brandList, mailList, categoryList, onFavouriteToggle, appLogo, onClose, onCatSelect, onSelect, selBrand, showDrawer, drawer }) => {
  const options = ["Brands", "Categories", "Mails"];
  const [option, setOption] = useState(options[0]);
  const [searchValue, setSearchValue] = useState("");
  const size = 88
  const styles = useGetBackButtonStyles({ size })
  // localStorage.setItem('Serchmail', JSON.stringify(searchValue));

  const onOptionChange = ({ target: { value } }) => {
    setOption(value);
    setSearchValue("");
  };

  return (
    <div css={classes.searchBrandsContainer}>
      <div css={classes.logo_img}><img height={38} src={appLogo} alt="money mails logo" onClick={onClose} /></div>
      <div>
        {/* <label onClick={() => showDrawer()}> Back </label> */}
        <IconButton aria-label="back" size="small" css={{
          color: '#222',
          fontSize: 12,
          marginLeft: 0,
          fontweight: 'bold'
        }}
          onClick={showDrawer}
        >
          <ArrowBackIosIcon />Back
        </IconButton>
      </div>
      <div css={classes.searchWrapper}>
        <SearchOutlined sx={classes.searchIcon} />
        <input
          css={classes.search}
          placeholder={`Search ${option}`}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <Radio.Group
        options={options}
        onChange={onOptionChange}
        value={option}
        optionType="button"
        buttonStyle="solid"
      />
      <div css={classes.brandCardsInSearchWrapper}>

        {option === options[0] ? (
          <>
            {brandList
              .filter((item, i) => {
                if (!searchValue) {
                  return true;
                }
                return item?.company_name
                  .toLowerCase()
                  .startsWith(searchValue?.toLowerCase());
              })
              .map((item, i) => {
                const BrandIcn = brandSvgs[i % brandSvgs.length].icn;
                const address = `${item?.company_name}, ${item?.address1},${item?.counties[0].name}, ${item?.states[0].name}-${item?.pincode}`;
                return (
                  <BrandIconCard
                    key={uuid()}
                    color={colorsForBrands[i % colorsForBrands.length]}
                    size={150}
                    showDrawer={showDrawer}
                    drawer={drawer}
                    onSelect={(e) => onSelect(e, item?.uuid)}
                    isActive={selBrand?.indexOf(item?.uuid) !== -1 || item.is_highlight === 'true' ? true : false}
                    isFavourite={item?.is_faviourate === 'true' ? true : false}
                    onFavouriteToggle={(e) => onFavouriteToggle(e, item?.uuid)}
                    showCardAction
                    address={address}
                  >
                    {item?.logo_url ? <img width={70} src={item?.logo_url} alt={item?.slug} /> : <BrandIcn width="70%" height="70%" fill="black" />}
                  </BrandIconCard>
                );
              })}
          </>
        ) :
          (
            option === options[1] ? (
              <>
                {categoryList
                  .filter((item, i) => {
                    if (!searchValue) {
                      return true;
                    }
                    return item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase());
                  })
                  .map((item, i) => {
                    const catItem = categoryList[i % categoryList.length] || categoriesSvgs[i % categoriesSvgs.length];
                    const CategoryIcn = catItem?.icn;
                    return (
                      <div css={classes.categoryWrapper} key={uuid()}>
                        <BrandIconCard
                          color={
                            colorsForCategories[i % colorsForCategories.length]
                          }
                          onSelect={() => onCatSelect('', item?.uuid)}
                          onActionClick={onClose}
                          size={120}
                          rounded
                        >
                          {item?.image ? <img width={70} src={item?.image} alt={item?.slug} /> : <CategoryIcn width="60%" height="60%" fill="black" />}
                        </BrandIconCard>
                        <span css={classes.categoryText}>{item?.name}</span>
                      </div>
                    );
                  })}
              </>
            ) :
              (
                <>
                  {mailList?.length > 0 ?
                    mailList.filter((item, i) => {
                      if (!searchValue) {
                        return true;
                      }
                      return item?.subject?.toLowerCase()?.includes(searchValue?.toLowerCase());
                    })
                      .map((item, i) => {
                        // item.length > 1 ? 
                        const catItem = mailList[i % mailList.length] || mailsSvgs[i % mailsSvgs.length]
                        const CategoryIcn = catItem?.icn;
                        return (
                          <div css={classes.categoryWrapper} key={uuid()}>
                            <BrandIconCard
                              color={
                                colorsFormails[i % colorsFormails.length]
                              }
                              // onSelect={() => onCatSelect(item?.uuid)}
                              onActionClick={onClose}
                              size={120}
                              rounded
                            >
                              {/* {item?.brand[0].logo_url ? <img width={70} src={item?.image_url} alt={item?.slug} /> : <CategoryIcn width="60%" height="60%" fill="black" />} */}

                              {item?.image_url ? <img width={70} src={item?.image_url} alt={item?.slug} /> : <CategoryIcn width="60%" height="60%" fill="black" />}
                            </BrandIconCard>
                            <span >{item?.message}</span>
                          </div>
                        )
                      })
                    :
                    <>
                      No Mails Availables
                    </>
                  }
                </>
              )
          )
        }
      </div>
    </div>
  );
};

export default DrawerSearchContent;
