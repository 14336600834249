/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import appLogo from "../../assets/images/svg/appLogo.svg";
import { useGetAppBarStyles } from "./index.styles";
import { Bingo_Url } from "../../connections";
import Bingo from './../../assets/images/Bingo.png'
import { Col, Container, Row } from "react-bootstrap";

const AppBar = ({ navItems, onDrawerHandler, drawerWidth = 0 }) => {
  const location = useLocation();
  const classes = useGetAppBarStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem('auth')) && location?.pathname !== "/signup") navigate("/login");
  }, [])

  const isMenu = location?.pathname === "/home" ? true : false;


  const refreshFun = () => {

    setTimeout(() => {
      if (JSON.parse(localStorage.getItem('auth'))) {
        navigate("/home")
        // window.location.reload(false);
      }
    }, 500)
  }
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 767
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [])
  return (
    <header css={[classes.header, { zIndex: 1251 }]}>
      {mobileView ? <Container
        className={classes.header}
        style={{ zIndex: 1251, padding: 0 }}  // Remove padding to avoid unexpected spacing
      >
        <Row>
          <Col xs={2} style={{ padding: '0 10px' }}>  {/* Adjust padding as needed */}
            <Row style={{paddingBottom : '5px'}}>
              <Col lg={12}>
                <img css={classes.logom} height={40}   src={appLogo} alt="money mails logo" onClick={() => refreshFun()} />
              </Col>
              <Col lg={12}>
                <a href={Bingo_Url} target="_blank" ><img css={classes.logo} height={38} src={Bingo} alt="Bingo logo" /></a>
              </Col>
            </Row>
          </Col>
          <Col xs={10} style={{ paddingLeft: '25%', paddingTop : '15px' }}> {/* Adjust padding as needed */}
            {navItems ? <nav css={classes.nav}>{navItems}</nav> : null}
          </Col>
        </Row>
      </Container>

        :

        <>

          {window.innerWidth > 768 && isMenu && <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={onDrawerHandler}
          >
            <MenuIcon />
          </IconButton>}
          {!mobileView &&
            <img css={classes.logo} height={40} src={appLogo} alt="money mails logo" onClick={() => refreshFun()} />
          }
          {mobileView &&
            <img css={classes.mobilelogo} height={40} src={appLogo} alt="money mails logo" onClick={() => refreshFun()} />
          }
          <a href={Bingo_Url} target="_blank" ><img css={classes.logo} height={38} src={Bingo} alt="Bingo logo"  style={{ marginLeft: '15px' }}/></a>


          {navItems ? <nav css={classes.nav}>{navItems}</nav> : null}
        </>}
    </header>
  );
};

export default AppBar;
