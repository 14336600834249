/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from "react";
import { useGetOfferCardStyles } from "./index.styles";
import { NEW_OFFER } from "../../constants";
import BrandIconCard from "../BrandIconCard";
import NikeSVG from "../../icons/nike";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChessQueen, faCrown, faEye } from '@fortawesome/free-solid-svg-icons';
import JewelIconSVG from "../../icons/jewelIcon";
import { FavoriteOutlined, Unsubscribe } from "@mui/icons-material";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { IconButton } from "@mui/material";
import Branding from './../../assets/images/Branding.jpg';
import Tooltip from '@mui/material/Tooltip';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { baseUrl } from "../../connections";
import { UserContext } from "../../context/UserContext";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{zIndex : 10000}}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Unsubscribe Mail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Are you sure you want to unsubscribe?</h4>
        <p>
          Once you unsubscribe, you will no longer receive this email, and it will not be available again.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.CLick}>Unsubscribe</Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}


const OfferCard = ({ onClick, UnsubscribeMailClick, setShow, show, showUnSuscribe, size, maxSize, height, maxHeight, mobile, containerStyles, isRedeemed, isLocation, item, message = '', isFavourite = false, onFavouriteMailToggle }) => {
  const classes = useGetOfferCardStyles({
    size,
    maxSize,
    containerStyles,
    height,
    maxHeight,
    mobile
  });
  const [isFav, setIsFav] = useState(isFavourite);
  const flat = item?.subject?.split(' ');

  const onFavClick = async (e) => {
    const favAddRes = await onFavouriteMailToggle(e, !isFav);

    setIsFav(!isFav);
    // isLocation();
    e.stopPropagation();
  };
  // const mailsvideo = (data) => {
  //   console.log(data, "itemitem64565tertry5445454")
  // }

  // const [show, setShow] = useState(false);
  const { userDetails } = useContext(UserContext);

  // const unSubscribeMail = () => {
  //   const payload = {
  //     "mail_uuid": item?.uuid,
  //     "user_uuid": userDetails?.uuid
  //   }
  //   axios.put(`${baseUrl}/mail/unsubscribe`, payload, {
  //     headers: {
  //       "x-auth-token": userDetails?.token
  //     }
  //   })
  //     .then((res) => {
  //       setShow(false);
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     })
  // }

  return (
    <div>
      {/* <Modal show={show} style={{ width: '100%' }}>
<h4>If You unsubscribe this mail. then no longer to visible here</h4>
<button>Unsubscribe</button> <button>Cancel</button>
        </Modal> */}
      {show &&
        <MyVerticallyCenteredModal
          show={show}
          onHide={() => setShow(false)}
          CLick={() => UnsubscribeMailClick()}
        />
      }
      <IconButton css={[classes.favIcon, { overflow: 'hidden', position: 'relative', top: '40px', left: mobile ? '65%' : showUnSuscribe ? '150px' : '175px', zIndex: '1000' }]} onClick={onFavClick} >
        {isFav ? (
          <FavoriteOutlined sx={{ color: "red" }} />
        ) : (
          <FavoriteBorderOutlinedIcon sx={{ color: "red" }} />
        )}

      </IconButton>
      {showUnSuscribe &&
      <IconButton css={[classes.favIcon, { overflow: 'hidden', position: 'relative', top: '40px', left: mobile ? '60%' : '140px', zIndex: '1000' }]} onClick={() => setShow(true)} >
        <Tooltip title="Unsubscribe Mail" placement="top-start" arrow>
          <Unsubscribe sx={{ color: "black" }} />
        </Tooltip>
      </IconButton>
      }
      <div css={[classes.container, containerStyles]} onClick={onClick}>
        <div css={classes.wrapper}>
          <div css={classes.cardHeaderWrapper}>
            {item?.type === 'paid' ? <FontAwesomeIcon icon={faCrown} className={`fa-2x fa-regular`} color="#eda213" /> : <FontAwesomeIcon icon={faChessQueen} className={`fa-2x fa-regular`} color="#54ac34" />}
            {/* {!isRedeemed && ( */}
            <span css={classes.cardFooterText}></span>
            {/* )} */}

          </div>
          <div>
            {item?.mime_type === 'image/png' || item?.mime_type === 'image/jpeg' ?
              <img key="1" alt={item?.brand[0]?.logo_url || Branding} src={item?.brand[0]?.logo_url || Branding} width={'auto'} height={30} style={{ width: 'auto', height: '40px' }} />
              : item?.mime_type === 'application/pdf' ?
                <img key="2" alt={item?.brand[0]?.logo_url || Branding} src={item?.brand[0]?.logo_url || Branding} width={'auto'} height={30} style={{ width: 'auto', height: '40px' }} />
                : item?.mime_type === 'application/icon' ?
                  <NikeSVG width="70%" height="70%" />
                  :
                  <img key="3" alt={item?.brand[0]?.logo_url || Branding} src={item?.brand[0]?.logo_url || Branding} width={'auto'} height={30} style={{ width: 'auto', height: '40px' }} />}
          </div>
          <div css={classes.offerTitleWrapper} >
            <span css={classes.offerTitle}>{flat?.[0]} {flat?.[1]}</span>
            <span css={classes.offerSubText}>{flat?.[2]} {flat?.[3]}</span>
          </div>
          <div css={{ marginTop: '-5px' }}>
            {item?.type === 'paid' && isRedeemed ? (
              <div css={classes.redeemTag}>Redeemed</div>
            ) : ''}
            {item?.type === 'paid' && item?.is_seen === "false" &&
              <div style={{ padding: 20 }}>
                Unseen
              </div>}
            {item?.type === "regular" && item.is_seen === "true" ? (
              <div style={{ padding: 20 }}>
                {/* <FontAwesomeIcon icon={faEye} className={`fa-2x fa-regular`} color="#54ac34" width={30} /> */}
                Seen
              </div>
            ) : ''}
            {item?.type === "regular" && item.is_seen === 'false' ? (
              <div style={{ padding: 20 }}>
                {/* <FontAwesomeIcon icon={faEye} className={`fa-2x fa-regular`} color="#54ac34" width={30} /> */}
                Unseen
              </div>
            ) : ''}
          </div>

        </div >
        {message && <div css={classes.cardFooter}>
          {message}
        </div>}
      </div>
    </div>
  );
};

OfferCard.defaultProps = {
  status: NEW_OFFER,
  onClick: void 0,
  offerDetails: {},
};

export default OfferCard;
