/** @jsxImportSource @emotion/react */

import Button from "../../components/Button";
import * as React from 'react';
import { useNavigate, Link } from "react-router-dom";
import { baseUrl } from "../../connections";
import { useContext, useEffect, useState } from "react";
import { useLogout } from "../../hooks/services/uselogout";

import signUpSVG from "../../assets/images/illustrations/signUp.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import AppBar from "../../components/AppBar";
import { useGetNavItems } from "../Home/appbarNav";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { UserContext } from "../../context/UserContext";
import Snackbar from "../../components/Snackbar";
import uuid from "react-uuid";
import { useTheme } from '@material-ui/core/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';

import { Grid } from "@mui/material";


const Accountdelete = () => {
    const { userDetails } = useContext(UserContext);
    const [success, setsuccess] = useState(false)
    const [errortoast, seterrortoast] = useState(false)

    const [open, setOpen] = React.useState(false);
    const { setPayloadsLogout } = useLogout();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlelogout = () => {
        setPayloadsLogout({
            user_uuid: userDetails?.user_uuid
        })
        setTimeout(() => {
            navigate("/login")
            // logoutHandler()
        }, 1000);
    }

    const navigate = useNavigate();

    const HandleDelete = (id) => {
        axios.delete(`${baseUrl}/user/delete/account/${userDetails?.user_uuid}`, {
            headers: {
                "x-auth-token": userDetails?.token
            }
        })
            .then((res) => {
                setTimeout(() => {
                    handleClose()
                    handlelogout()
                }, 1000);
                setsuccess(true)

            })
            .catch((err) => {
                seterrortoast(true)
                // <Snackbar/>
                // toast.error(err.response.data)
            })
    }

    const RedirectFunction = () => {
        setTimeout(() => {
            navigate("/home")
        }, 1000)
    }



    return (
        <>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                Are you sure you want to delete your account?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {/* "Are you sure you want to delete this account?" */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={HandleDelete} >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* <AppBar /> */}
            <AppBar navItems={useGetNavItems({})} />
            <div style={{ display: "flex", justifyContent: "center", marginTop: "10%" }}>
                {/* <div style={{ marginRight: "10%" }}>
                    <img
                        src={signUpSVG}
                        alt="sign up illustration"
                        width="100%"
                        height="550px"
                    />
                </div> */}
                {/* Are you sure you want to delete your account? */}

                {/* By selecting "Yes", your account and all associated reward points will be permanently deleted. This action cannot be undone. */}
                <div>
                    <form onSubmit={(e) => e.preventDefault()}>
                        {/* <h2 style={{ display: "flex", marginBottom: "50px" }}>Are you sure you want to delete your account?</h2> */}
                        <h6 style={{ display: "flex", marginBottom: "30px" }}>Are you sure you want to delete your account?</h6>
                        <div>
                            <p style={{ display: "flex", marginBottom: "20px" }}>By selecting "Delete", your account and all associated reward points will be permanently deleted. This action cannot be undone. </p>
                            {/* <p style={{ display: "flex", marginBottom: "20px" }}>2. Once you've received the verification code, proceed to log in using your credentials. </p> */}
                            {/* <p style={{ display: "flex", marginBottom: "20px" }}>3.  After successfully logging in, you will need to complete the verification process using the received code. </p> */}
                            {/* <p style={{ display: "flex", marginBottom: "20px" }}>4. In case you do not receive the code, please reach out to the administrator for assistance. </p> */}
                        </div>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            style={{ marginRight: '10px' }}
                            // sx={{ width: 1 / 3 }}
                            onClick={RedirectFunction}
                        >
                            {/* <div style={{ height: '20px', display: "flex", justifyContent: "space-between" }}> */}
                            <span>Back</span>
                            <ArrowForwardIcon />
                            {/* </div> */}
                        </Button>
                     
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            // sx={{ width: 1 / 3 }}
                            onClick={handleClickOpen}
                        >
                            {/* <div style={{ height: '20px', display: "flex", justifyContent: "space-between" }}> */}
                            <span>Delete</span>
                            <DeleteOutlinedIcon />
                            {/* </div> */}
                        </Button>
                    </form>
                </div>

            </div>
            {errortoast === true &&
                <Snackbar key={uuid()} message="Something went Wrong!" isOpen={errortoast} />
            }
            {success === true &&
                <Snackbar key={uuid()} message={"Account deleted successfully!"} isOpen={success} severity={'success'} />
            }
        </>
    );
};

export default Accountdelete;
