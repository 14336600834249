/** @jsxImportSource @emotion/react */
// import React, { useEffect, useState } from "react";
import Slide from '@mui/material/Slide';
import React, { useEffect, useRef, useState, useContext, memo } from "react";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import Drawer from "@mui/material/Drawer";
import { IconButton, Box } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate, useLocation, useSearchParams, NavLink } from "react-router-dom";
import { Row, Col, Form, Card, Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useGetBackButtonStyles } from "../../components/BackButton/index.styles";
import ReactPlayer from 'react-player';
import Stories, { WithSeeMore } from "react-insta-stories";
import { usePopupseen } from '../../hooks/services/PopupSeen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Typography, ClickAwayListener } from "@mui/material";
// import Tooltip from '@mui/material/Tooltip';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from "../../components/Button";
import AppBar from "../../components/AppBar";
// import AppFooter from "../../components/AppFooter";
import Modal from 'react-bootstrap/Modal';
import axios from 'axios'
import Footer1 from "../../components/Footer/Footer";
import BrandIconCard from "../../components/BrandIconCard";
import { useGetHomeScreenStyles } from "./index.styles";
import ReebokSVG from "../../icons/reebok";
import AdidasSvg from "../../icons/adidas";
import NikeSVG from "../../icons/nike";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import uuid from "react-uuid";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TabView from "../../components/Tabs";
import OfferCard from "../../components/OfferCard";
import Dropdown from "../../components/Dropdown";
import DrawerSearchContent from "./drawerSearchContent";
import { useInterval } from "../../hooks/useInterval";
import { useGetNavItems } from "./appbarNav";
import { useHome } from "../../hooks/services/useHome";
import { useBrand } from "../../hooks/services/useBrand";
import { useCategory } from "../../hooks/services/useCategory";
import { useSubCategory } from "../../hooks/services/useSubCategory";
import { useMail } from "../../hooks/services/useMail";
import { useCountry } from "../../hooks/services/useCountry";
import { UserContext } from "../../context/UserContext";
import { useRegions } from '../../hooks/services/religion';
import appLogo from "../../assets/images/svg/appLogo.svg";
import { useLogin } from "../../hooks/services/useLogin";
import { useStateData } from "../../hooks/services/useStateData";
import { useCountyData } from "../../hooks/services/useCountyData";
import { useCityData } from "../../hooks/services/useCityData";
import Snackbar from "../../components/Snackbar";
import useGetColors from "../../utils/colors";
import { NO_RESULT } from "../../constants";
import { COMING_SOON } from "../../constants";
import { Notificationconsumer } from "../../hooks/services/notifications";
import { usePopupseenuser } from '../../hooks/services/popupseenuser';
import { useProfile } from '../../hooks/services/Profile';
import ToggleButton from 'react-toggle-button'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { baseUrl } from "../../connections";
import { usePopAddslist } from "../../hooks/services/usePopupadds";
import { useModellist } from '../../hooks/services/useModels';
import { Useseenmodels } from '../../hooks/services/modelseen';
import { usePopupsdatadetails } from '../../hooks/services/usePopupaddsdetails';
import Carousel from 'react-bootstrap/Carousel';
// import Button from 'react-bootstrap/Button';
// import ToggleButton from 'react-toggle-button'

// Import the main component
import { Viewer } from '@react-pdf-viewer/core';


// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// Import the styles
// import '@react-pdf-viewer/core/lib/styles/index.css';

const brandSvgs = [NikeSVG, ReebokSVG, AdidasSvg];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@6.2.2/build/pdf.worker.min.js`;

const Home = () => {

  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const location = useLocation();
  const size = 88
  const styles = useGetBackButtonStyles({ size })
  let drawerWidth = 240;
  const classes = useGetHomeScreenStyles();
  const navigate = useNavigate();
  const { offerCardMultiColors: colors } = useGetColors();
  const { userDetails, activeBrand, setActiveBrand } = useContext(UserContext);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerContentRoute, setDrawerContentRoute] = useState("search");
  const [seconds, setSeconds] = useState(0);
  const [selectedOfferIndex, setSelectedOfferIndex] = useState();
  const [redeemedOfferIndexes, setRedeemedOfferIndexes] = useState([]);
  const offerDetailsCardRef = useRef(null);
  const { data, setPayload } = useHome();
  const { barndList, brandCount, brandpayload1, setBrandPayload1, setBrandPayload, setUpdateBrandFav, isBrandLoading } = useBrand();
  const { popupADD, setpopupADD } = useLogin();
  const { categoryList, setCatPayload, setCatPayload1, payload1 } = useCategory();
  const { subCategoryList = [], setSubCatPayload, setSubParam, setCatParam } = useSubCategory();

  const { setPayloadsPopupseen, popupseendata } = usePopupseen()
  const { setPayloadsPopupseenuser } = usePopupseenuser()
  const { setusermodelpayload } = Useseenmodels()

  const { Setprofile, Profilelist } = useProfile()
  const { mailList, mailCount, isRedeem, redeemMessage, redeemError, mailHTML, setMailPayload, setUpdateMailFav, setUpdateMailRedeem, setMailURL, isMailLoading, redeemmsg } = useMail();
  const [subCatActive, setSubCatActive] = useState('');
  const [subCatActive1, setSubCatActive1] = useState('');
  const { countryList, setCountry } = useCountry();
  const { stateData, setStateData } = useStateData();
  const { ReligionData, setPayloadsreligion } = useRegions()
  const { countyData, setCountyData } = useCountyData();
  const { cityData, setCityData } = useCityData();
  const [selCountry, setSelCountry] = useState();
  const [selState, setSelState] = useState();
  const [selCounty, setSelCounty] = useState('');
  const [selCity, setSelCity] = useState();
  const [cityactive, setcityactive] = useState('')
  const [offerCard, setOfferCard] = useState({});
  const [selCategory, setSelCategory] = useState('');
  const [selBrand, setSelBrand] = useState([]);
  const [numPages, setNumPages] = useState(1);
  const [isAnimate, setIsAnimate] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [showLeftDrawer, setShowLeftDrawer] = useState(true);
  const [showRightDrawer, setShowRightDrawer] = useState(false);
  const [locationCheck, setLocationCheck] = useState(false)
  const [redeemmsgs, setredeemmsgs] = useState(false)
  const [madelvideo, setmadelvideo] = useState('')
  const [showVideo, setShowVideo] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams(window.location.search);
  let userdata = JSON.parse(localStorage.getItem("user"));
  const { Popupaddsdata, setPayloads } = usePopAddslist();
  const { modeldata, setModelPayloads } = useModellist();

  // useModellist
  const { setPayloadspopup, Popupaddsdatadetails1, setDatapopup } = usePopupsdatadetails()
  const Popupaddsdata1 = Popupaddsdata?.data
  // useEffect(() => {
  //   setSearchParams(window.location.search)
  // },[])
  useEffect(() => {
    const data = {
      id: userdata?.uuid,

    }
    setPayloads(data)
    Setprofile(userdata?.uuid)
    setModelPayloads(userdata?.uuid)


  }, [])
  const [popupseen, setpopupseen] = useState(false)


  useEffect(() => {

    if (Profilelist?.data?.pop_up === false) {
      setShow(true)
    }
    else if (Profilelist?.data?.pop_up === true) {
      setShow(false)

    }

  }, [Profilelist])

  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  const goToNextAd = (data) => {
    // setCurrentAdIndex((prevIndex) => (prevIndex + 1) % Popupaddsdata1?.length);
    setusermodelpayload({
      // uuid
      user_uuid: userdata?.uuid,
      modal_uuid: data
    })
  };

  const goToPrevAd = () => {
    setCurrentAdIndex((prevIndex) =>
      prevIndex === 0 ? Popupaddsdata1?.length - 1 : prevIndex - 1
    );
  };

  const [searchCat, setSearchCat] = useState(searchParams.get('cat'))
  const [searchBrand, setSearchBrand] = useState(searchParams.get('brand'))
  const [searchSubCat, setSearchSubCat] = useState(searchParams.get('sub'))
  const [popupdatadetails, setpopupdatadetails] = useState('')
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const removeQueryParams = () => {
    // const param = searchParams.get('sub');

    // if (param) {
    // 👇️ delete each query param
    searchParams.delete('cat');
    searchParams.delete('brand');
    searchParams.delete('sub');

    // 👇️ update state after
    setSearchParams(searchParams);
    // }
  };

  const [show, setShow] = useState('');
  const [openShow, setOpenShow] = useState(0);
  useEffect(() => {
    setPayloadsreligion('name')
  }, [])



  // useEffect(() => {
  //  {popupADD === "" || popupADD === 'null'  && 
  //   setShow(true)} 
  // }, [])

  // const handleShow = () => setShow(true);
  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      background: 'white',
      padding: '20px',
      borderRadius: '8px',
      position: 'relative',
      width: '80%',
      maxWidth: '600px',
    },
    closeButton: {
      position: 'absolute',
      top: '55px',
      // right: '10px',
      left: '117%',
      background: 'none',
      border: 'none',
      fontSize: '24px',
      cursor: 'pointer',
    },
    Closebutton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      // left: '117%',
      background: 'none',
      border: 'none',
      fontSize: '30px',
      cursor: 'pointer',
    },
    body: {
      textAlign: 'center',
    },
    image: {
      maxWidth: '100%',
      backgroundColor: 'black',
      height: 'auto',
    },
  };


  useEffect(() => {
    setSearchCat(searchParams.get('cat'));
    setSearchBrand(searchParams.get('brand'));
    setSearchSubCat(searchParams.get('sub'));

  }, [searchParams]);

  // toggle button

  const [seen, setseen] = useState('')

  // toggle button



  const isOfferContentDrawerActive = drawerContentRoute === "offerCard";

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    // if (selCategory !== '') {
    if (barndList && barndList?.length > 0) {
      onBrandSelect(true, barndList[0]?.uuid)
    }
    // }
  }, [barndList])


  // useEffect(() => {
  //   const processPopupData = async () => {
  //     if (popupseen === true) {

  //       setTimeout(() => {

  //       }, 1000);


  //       for (const item of Popupaddsdata1) {

  //         await setPayloadsPopupseen({
  //           user_uuid: userdata?.uuid
  //         });

  //         await setPayloadsPopupseenuser({
  //           user_uuid: userdata?.uuid,
  //           popup_uuid: item?.uuid
  //         });


  //       }


  //     }
  //   };

  //   processPopupData();

  // }, [popupseen, Popupaddsdata1]);    // useEffect(() => {
  //   if (popupseen === true) {
  //     alert('ok p');
  //     const newPayloadsPopupseenuser = Popupaddsdata1.map((item) => {
  //       setPayloadsPopupseen({
  //         user_uuid: userdata?.uuid
  //       });
  //       return {
  //         user_uuid: userdata?.uuid,
  //         popup_uuid: item?.uuid
  //       };
  //     });

  //     setPayloadsPopupseenuser(newPayloadsPopupseenuser);
  //   }
  // }, [popupseen, Popupaddsdata1, userdata]);


  const [open1, setOpen1] = useState(false);

  const handleClose1 = () => {
    setOpen1(false)
  }
  const handleClickOpen1 = (event) => {
    // sethistoryData(event)
    setOpen1(true);
    // setpopupseen(true)
    // alert('okkkkk time')
    // setTimeout(() => {
    // setPayloads(userdata?.uuid)
    // setTimeout(() => {


    //   axios.get(`${baseUrl}/popup/unseen/count?user_uuid=${userdata?.uuid}`, {
    //     headers: {
    //       "x-auth-token": userdata && userdata?.token
    //     }
    //   })
    //     .then((res) => {

    //       setDatapopup(res?.data?.count)
    //       setpopupdatadetails(res?.data?.count)
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //     })
    // }, 1500);

    // }, 2000);
    // setStateChange1(!stateChange1)
    // setStateChange(!stateChange)
  };
  // useEffect(() => {
  //   if (performance?.navigation?.type === 1) {
  //     navigate(location.pathname, { replace: true });
  //   }
  // }, []);

  // const mailsearch = localStorage.getItem("Serchmail")




  const { noticationdata, setPayloadsdata, unread } = Notificationconsumer();
  useEffect(() => {
    setPayloadsdata(userdata?.uuid);

  }, [])
  const handleVideomadel = (item) => {
    setShowVideo(false)
    // handleNavLinksClick("offerCard", item);
  }

  const handleClose = (item) => {
    setShow(false);

    setPayloadsPopupseen({
      user_uuid: userdata?.uuid
    })
    setusermodelpayload({
      // uuid
      user_uuid: userdata?.uuid,
      modal_uuid: item
    })
    Popupaddsdata1.forEach((item) => {
      setPayloadsPopupseenuser({
        // uuid
        user_uuid: userdata?.uuid,
        popup_uuid: item?.uuid
      })
    })
    setOpenShow(openShow + 1)
  }
  const popuplistuser = (item, event) => {
    event.preventDefault();
    setPayloads(userdata?.uuid)
    setPayloadsPopupseenuser({
      // uuid
      user_uuid: userdata?.uuid,
      popup_uuid: item
    })
    // alert('ok ttd')


  }

  useEffect(() => {
    if (searchCat) {
      setSelCategory(searchCat)
    }
    else if (location?.state?.data?.selCategory) {
      setSelCategory(location?.state?.data?.selCategory)
    }
    else if (categoryList) {
      setSelCategory(selCategory ? selCategory : categoryList[0]?.uuid);
    }
  }, [categoryList, searchCat])

  const { start, stop } = useInterval((count) => {
    setSeconds(count);
    if (count === 18) {
      setSeconds(0);
      setIsAnimate(true);
      stop();
    }
  });

  const carouselContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    maxWidth: '600px',
    margin: 'auto',
  };

  const imageStyle1 = {
    width: '80%',
    maxWidth: '80%',
    height: '80%',
  };

  const arrowButtonStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
    zIndex: 2,
    // right: '30px',
  };

  const prevButtonStyle = {
    ...arrowButtonStyle,
    left: '10px',
  };

  const nextButtonStyle = {
    ...arrowButtonStyle,
    right: '10px',
  };



  useEffect(() => {
    if (location?.state?.data?.selCountry) {
      setSelCountry(location?.state?.data?.selCountry);
      setSelState(location?.state?.data?.selState);
      setSelCounty(location?.state?.data?.selCounty);
      setSelCity(cityactive === true ? selCity : location?.state?.data?.selCity);
      // cityactive,selCity

      // if (location?.state?.data?.selCountry) {
      //   setStateData(location?.state?.data?.selCountry);
      // }
      // if (location?.state?.data?.selState) {
      //   setCountyData(location?.state?.data?.selState);
      // }
      // if (location?.state?.data?.selCounty) {
      //   setCityData(location?.state?.data?.selCounty);
      // }
      // if (location?.state?.data?.selCity) setSelCity(location?.state?.data?.selCity);
    }
    else {
      setSelCountry(userDetails?.address?.country || userDetails?.country);
      setSelState(userDetails?.address?.state || userDetails?.state);
      setSelCounty(userDetails?.address?.county || userDetails?.county);
      setSelCity(cityactive === true ? selCity : userDetails?.address?.city || userDetails?.city);
      // if (userDetails?.address?.country) {
      //   setStateData(userDetails?.address?.country);
      // }
      // if (userDetails?.address?.state) {
      //   setCountyData(userDetails?.address?.state);
      // }
      // if (userDetails?.address?.county) {
      //   setCityData(userDetails?.address?.county);
      // }
      // if (userDetails?.address?.city) {
      //   setSelCity(userDetails?.address?.city);
      // }
    }
  }, [userDetails, mailList, mailCount])

  const [mailSubscribeUpdate, setmailSubscribeUpdate] = useState(false);

  useEffect(() => {
    let indexValue = 0
    // const defBrandPayload = `pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 12}&user_uuid=${userDetails?.uuid}`
    // setBrandPayload(`${defBrandPayload}&category_uuid=${selCategory}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}`)
    if (subCategoryList && subCategoryList?.length > 0 && showVideo === false) {
      const isEven = (value) => value.is_mail_available === "true";

      const checkMail = subCategoryList.filter(isEven);

      if (checkMail?.length > 0) {
        subCategoryList && subCategoryList.find((val, undex) => {

          if (searchSubCat !== '') {
            if (searchSubCat === val?.uuid) {
              setSubCatActive(searchSubCat)
              setTabindexs(undex)
              const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
              if (cityactive === true) {
                setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}&city_uuid=${selCity}`);
                setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=&sub_category_uuid=&type=filter&search=&pagenum=&limit=&seen=${seen}` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${searchSubCat}&type=filter&search=&pagenum=&limit=&seen=${seen}` : `${defPayload}&sub_category_uuid=${searchSubCat}&category_uuid=${selCategory}&type=filter&search=&pagenum=&limit=&seen=${seen}`);
              } else {
                // setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=${selBrand}&sub_category_uuid=${searchSubCat}&seen=${seen}` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${searchSubCat}&seen=${seen}` : `${defPayload}&sub_category_uuid=${searchSubCat}&category_uuid=${selCategory}&search=&pagenum=&limit=&seen=${seen}`);
                setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
              }
            }
          }
          else if (val.is_mail_available === "true" && indexValue === 0) {
            indexValue = indexValue + 1
            setSubCatActive(val?.uuid || subCategoryList[0].uuid);
            setTabindexs(undex)
            if (cityactive === true) {
              // ${selBrand}
              console.log(searchSubCat, val?.uuid, val.is_mail_available, indexValue, "sadsadsadasdsadasd mail")

              // ${val?.uuid || subCategoryList[0].uuid}
              const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
              setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}&city_uuid=${selCity}`);
              setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=&sub_category_uuid=&type=filter&search=&pagenum=&limit=&seen=${seen}` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${val?.uuid || subCategoryList[0].uuid}&type=filter&search=&pagenum=&limit=&seen=${seen}` : `${defPayload}&sub_category_uuid=${val?.uuid || subCategoryList[0].uuid}&category_uuid=${selCategory}&type=filter&search=&pagenum=&limit=&seen=${seen}`);
            } else {
              const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
              setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
              console.log(searchSubCat, val?.uuid, val.is_mail_available, indexValue, "sadsadsadasdsadasd mail1")
              setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=${selBrand}&sub_category_uuid=${val?.uuid || subCategoryList[0].uuid}&seen=${seen}` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${val?.uuid || subCategoryList[0].uuid}&seen=${seen}` : `${defPayload}&sub_category_uuid=${val?.uuid || subCategoryList[0].uuid}&category_uuid=${selCategory}&search=&pagenum=&limit=&seen=${seen}`);
              console.log(searchSubCat, val?.uuid, val.is_mail_available, indexValue, "sadsadsadasdsadasd mail2")
            }
          }
        });
      }
      else {
        setSubCatActive(subCategoryList[0].uuid);
        setTabindexs(0)
        const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
        if (cityactive === true) {
          setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=&sub_category_uuid=&type=filter&search=&pagenum=&limit=&seen=${seen}` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${subCategoryList[0].uuid}&type=filter&search=&pagenum=&limit=&seen=${seen}` : `${defPayload}&sub_category_uuid=${subCategoryList[0].uuid}&category_uuid=${selCategory}&type=filter&search=&pagenum=&limit=&seen=${seen}`);
          setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}&city_uuid=${selCity}`);
        } else {
          setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=${selBrand}&sub_category_uuid=${subCategoryList[0].uuid}&seen=${seen}` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${subCategoryList[0].uuid}&seen=${seen}` : `${defPayload}&sub_category_uuid=${subCategoryList[0].uuid}&category_uuid=${selCategory}&search=&pagenum=&limit=&seen=${seen}`);
          setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
        }

      }
    }
  }, [subCategoryList, selBrand, selCategory, searchSubCat, showVideo, mailSubscribeUpdate])

  // useEffect(())
  useEffect(() => {
    // if (cityactive === true) {
    if (cityactive === true) {
      setSubCatPayload(`country_uuid=${selCountry ? selCountry : ''}&state_uuid=${selState ? selState : ''}&city_uuid=${selCity ? selCity : ''}&county_uuid=${selCounty ? selCounty : ''}&search=`);
      setPayload(`page_name=home`);
      setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 16}&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=`);
      setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}&city_uuid=${selCity}`);
      setCountry(`page=0&limit=10&search=UnitedStates`);
    } else {
      setSubCatPayload(`country_uuid=${selCountry ? selCountry : ''}&state_uuid=${selState ? selState : ''}&city_uuid=${selCity ? selCity : ''}&county_uuid=${selCounty ? selCounty : ''}&search=`);
      setPayload(`page_name=home`);
      setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 16}&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);
      setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
      setCountry(`page=0&limit=10&search=UnitedStates`);
    }
    // if (subCatActive && (selCountry || selState || selCounty || selCity)) setMailPayload(`country_uuid=${selCountry}&brand_uuid=${selBrand}&state_uuid=${selState}&city_uuid=${selCity}&sub_category_uuid=${subCatActive}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}&category_uuid=${selCategory}&type=filter`);
  }, [selCountry, selState, selCounty, selCity, selCategory, userDetails, mailSubscribeUpdate]);



  const onTabChane = (sub_category_uuid) => {
    setSearchSubCat('')
    setSubCatActive(sub_category_uuid);
    // setSubCatActive1(sub_category_uuid);

    const defUrl = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&sub_category_uuid=${sub_category_uuid}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
    // const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&sub_category_uuid=${subCatActive}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`
    // const defBrandPayload = `pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 12}&user_uuid=${userDetails?.uuid}`
    if (cityactive === true) {
      // alert('ok if')
      setMailPayload(selBrand?.length > 0 ? `${defUrl}&brand_uuid=${selBrand}&category_uuid=${selCategory}&type=filter&search=&pagenum=&limit=&seen=${seen}` : selCategory ? `${defUrl}&category_uuid=${selCategory}&type=filter&search=&pagenum=&limit=&seen=${seen}` : `${defUrl}`)
    } else {
      // alert('ok else')
      setMailPayload(selBrand?.length > 0 ? `${defUrl}&brand_uuid=${selBrand}&category_uuid=${selCategory}&seen=${seen}` : selCategory ? `${defUrl}&category_uuid=${selCategory}&search=&pagenum=&limit=&seen=${seen}` : `${defUrl}`)
    }

    // setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 16}&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);

    // setBrandPayload(selBrand?.length > 0 ? `${defBrandPayload}&category_uuid=${selCategory}` : `${defBrandPayload}&s=sdd`)
  };

  // useEffect(() => {
  //   if (isOfferContentDrawerActive) {
  //     start();
  //   }
  // }, [isOfferContentDrawerActive]);

  useEffect(() => {
    if (drawerContentRoute === "offerCard") {
      // start();
      console.log('saddsf');
    }
  }, [drawerContentRoute]);

  useEffect(() => {
    if (redeemError === false && offerCard?.type === "paid") {
      setShowDrawer(true)
    }
    if (offerCard?.type === "paid") {
      setShowDrawer(true)
    }
    if (isRedeem) {
      setShowDrawer(true)
    }

  }, [redeemError, isRedeem])

  useEffect(() => {
    if (!showDrawer) {
      setBrandPayload(`pagenum=0&limit=16&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);
    }
  }, [showDrawer])

  const [currentIndex, setCurrentIndex] = useState(0);
  const currentCard = mailList[currentIndex];

  const handleNavLinksClick = (route, item) => {

    setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 16}&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);
    setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
    if (item?.mime_type === 'text/html') {
      const url = item?.image_url?.split('/');
      setMailURL({ html_url: url.slice(-1)[0] })
    }
    setIsAnimate(false);
    setOfferCard(item);
    if (route === 'offerCard') {
      setDrawerContentRoute(route);
      // if (item?.city_uuid !== '') {
      //   if ((item?.country_uuid === userDetails?.address?.country) && (item?.state_uuid === userDetails?.address?.state) &&
      //     (item?.county_uuid === userDetails?.address?.county) && (item?.city_uuid === userDetails?.address?.city)) {
      //     setDrawerContentRoute(route);
      //     //  setShowDrawer(true);

      //   }
      //   else {
      //     console.log("location Not matched")
      //     // setLocationCheck(true)
      //   }
      // }
      // else if (item?.county_uuid !== '') {
      //   if ((item?.country_uuid === userDetails?.address?.country) && (item?.state_uuid === userDetails?.address?.state) &&
      //     (item?.county_uuid === userDetails?.address?.county)) {
      //     setDrawerContentRoute(route);
      //     // setShowDrawer(true);

      //   }
      //   else {
      //     console.log("location Not matched")
      //     // setLocationCheck(true)
      //   }
      // }
      // else if (item?.state_uuid !== '') {
      //   if ((item?.country_uuid === userDetails?.address?.country) && (item?.state_uuid === userDetails?.address?.state)) {
      //     setDrawerContentRoute(route);
      //     // setShowDrawer(true);

      //   }
      //   else {
      //     console.log("location Not matched")
      //     // setLocationCheck(true)
      //   }
      // }
      // else if (item?.country_uuid !== '') {
      //   if (item?.country_uuid === userDetails?.address?.country) {
      //     setDrawerContentRoute(route);
      //     // setShowDrawer(true);

      //   }
      //   else {
      //     console.log("location Not matched")
      //     // setLocationCheck(true)
      //   }
      // }
    }
    else if (route === 'location' || route === 'search') {
      setDrawerContentRoute(route);
      setShowDrawer(true);
      setBrandPayload(`pagenum=0&limit=${route === 'search' ? 100 : 16}&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);
    }
    {
      item?.type === "regular" &&
        // setShowDrawer(true);
        setUpdateMailRedeem({
          user_uuid: userDetails?.uuid,
          mail_uuid: item?.uuid
        });
    }
    // setTimeout(() => {
    {
      item?.type !== "regular" &&
        setUpdateMailRedeem({
          user_uuid: userDetails?.uuid,
          mail_uuid: item?.uuid
        });
    }
    // }, 2000);
  };

  const toggleDrawer = (event) => {
    if (
      (event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")) ||
      isOfferContentDrawerActive
    ) {
      return;
    }
    setShowDrawer(false);
  };

  const onFavouriteToggle = (e, brand_uuid) => {
    e.preventDefault();
    setUpdateBrandFav({
      user_uuid: userDetails?.uuid,
      brand_uuid: brand_uuid
    });
  }

  const onFavouriteMailToggle = (e, mail_uuid, item) => {
    e.preventDefault();
    if (locationCheck) setLocationCheck(false);
    if (item?.city_uuid !== '') {
      if ((item?.country_uuid === userDetails?.address?.country)) {
        // && (item?.state_uuid === userDetails?.address?.state) &&
        // (item?.county_uuid === userDetails?.address?.county)
        // && (item?.city_uuid === userDetails?.address?.city)
        setUpdateMailFav({
          user_uuid: userDetails?.uuid,
          mail_uuid: mail_uuid
        });
      }
      else {
        setLocationCheck(true)
      }
    }
    else if (item?.county_uuid !== '') {
      if ((item?.country_uuid === userDetails?.address?.country)) {
        // && (item?.state_uuid === userDetails?.address?.state) &&
        // (item?.county_uuid === userDetails?.address?.county)
        setUpdateMailFav({
          user_uuid: userDetails?.uuid,
          mail_uuid: mail_uuid
        });
      }
      else {
        setLocationCheck(true)
      }
    }
    else if (item?.state_uuid !== '') {
      if ((item?.country_uuid === userDetails?.address?.country)) {
        // && (item?.state_uuid === userDetails?.address?.state)
        setUpdateMailFav({
          user_uuid: userDetails?.uuid,
          mail_uuid: mail_uuid
        });
      }
      else {
        setLocationCheck(true)
      }
    }
    else if (item?.country_uuid !== '') {
      if (item?.country_uuid === userDetails?.address?.country) {
        setUpdateMailFav({
          user_uuid: userDetails?.uuid,
          mail_uuid: mail_uuid
        });
      }
      else {
        setLocationCheck(true)
      }
    }
  }

  const [mailscroll, setmailscroll] = useState(false)

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % mailList.length);
    setmailscroll(true)
    const currentCard = mailList[currentIndex + 1];

    {
      currentCard?.type !== "regular" &&
        setUpdateMailRedeem({
          user_uuid: userDetails?.uuid,
          mail_uuid: currentCard?.uuid
        });
      setredeemmsgs(currentCard?.is_seen)

      setTimeout(() => {
        setredeemmsgs(false)
      }, 4500);
    }


    // currentIndex
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + mailList.length) % mailList.length);
    setmailscroll(true)

    const currentCard = mailList[currentIndex - 1];

    {
      currentCard?.type !== "regular" &&
        setUpdateMailRedeem({
          user_uuid: userDetails?.uuid,
          mail_uuid: currentCard?.uuid
        });
      setredeemmsgs(currentCard?.is_seen)
      setTimeout(() => {
        setredeemmsgs(false)
      }, 4500);
    }
  };


  const onChangeHandler = (id, val) => {
    // setSelCategory('')
    // setSelBrand('')
    if (id === 'country') {
      setSelCountry(val);
      setStateData(val);
      setSelState('');
      setSelCity('');
      setSelCounty('');

    }
    if (id === 'state') {
      setSelState(val);
      setSelCounty('');
      setSelCity('');
      setCountyData(val);
    }
    if (id === 'county') {
      setSelCounty(val);
      setSelCity('');
      setCityData(val);
    }
    if (id === 'city') setSelCity(val);

  };
  const [isFirstSelection, setIsFirstSelection] = useState(true);

  const onChangeCategoryHandler = (value) => {
    const isExist = selCategory === value ? true : false;
    setSelBrand([]);
    setSearchSubCat('')
    setSelCategory((prev) => prev !== value ? value : prev);
    // setSelCategory(value)
    setSubParam('')
    // setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
    // const data = payload1 + "dd"
    // setCatPayload1(data)
    if (isFirstSelection) {
      setCatParam(value);
      setIsFirstSelection(false);
    } else {
      setCatParam((prev) => prev !== value ? value : prev);
    }
    // setCatParam((prev) => console.log(prev !== value ? value : !prev, "ghsdvfhdsghgdsjgd"))
    if (showDrawer) setShowDrawer(false);
    const defBrandPayload = `pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 16}&user_uuid=${userDetails?.uuid}`
    // ${subCatActive}
    // ${selBrand}
    const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&sub_category_uuid=&category_uuid=${value}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`
    if (cityactive === true) {
      setMailPayload(!isExist ? `${defPayload}&brand_uuid=&type=filter&search=&pagenum=&limit=` : `${defPayload}&brand_uuid=&type=filter&search=&pagenum=&limit=`)
      setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 16}&user_uuid=${userDetails?.uuid}&category_uuid=${value}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=&type=filter`);
      // ${selBrand ? selBrand : ''}
      setSubCatPayload(`country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&search=&type=filter`);
      setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}&user_uuid=${userDetails?.uuid}`);
    } else {
      setMailPayload(!isExist ? `${defPayload}&category_uuid=${value}&brand_uuid=` : `${defPayload}&brand_uuid=&search=&pagenum=&limit=`)
      setBrandPayload(!isExist ? `${defBrandPayload}&category_uuid=${value}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}` : `${defBrandPayload}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&category_uuid=${value}`)
      setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 16}&user_uuid=${userDetails?.uuid}&category_uuid=${value}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);
      setSubCatPayload(`country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&search=`);
      setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
    }


    removeQueryParams();
  }

  useEffect(() => {
    const data = payload1 + "dd"
    setCatPayload1(data)
    const data1 = brandpayload1 + "dd"
    setBrandPayload1(data1)
    if (cityactive !== true) {
      setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
      setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 16}&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);

    }

    if (categoryList?.length > 0) {
      setSelCategory(selCategory ? selCategory : categoryList[0]?.uuid);
    }
    // onBrandSelect(true, barndList[0]?.uuid)
  }, [isRedeem])

  useEffect(() => {
    if (searchBrand) {
      const brand = [searchBrand];
      setSelBrand([...brand]);
      setTimeout(function () {
        onBrandSelect(true, searchBrand);
      }, 800);
    }
    else if (location?.state) {
      const brand = [location?.state?.id];
      setSelBrand([...brand]);
      setTimeout(function () {
        onBrandSelect(true, location?.state?.id);
      }, 800);
    }
    else if (activeBrand && activeBrand?.length > 0) {
      const brand = [activeBrand];
      setSelBrand([...brand]);
      setTimeout(function () {
        onBrandSelect(true, activeBrand);
      }, 800);
    }
    else if (selBrand && selBrand?.length > 0) {
      setTimeout(function () {
        onBrandSelect(true, selBrand[0]);
      }, 800);
    }
  }, [selCountry, searchBrand])

  const onBrandSelect = (e, brandId, brandDetails) => {
    // setShowDrawer(false);
    // setTabindexs(0)
    // setSubCatActive(subCategoryList[0]?.uuid)
    // subCategoryList?.map((ite, ind) => {
    //   if( mailList[0]?.sub_category_uuid === ite?.uuid) setTabindexs(ind);
    //   console.log(mailList[0]?.sub_category_uuid, ite?.uuid, ind, "555")
    // })

    const brandList = [...selBrand];
    const brand = e ? [brandId] : selBrand.filter((el) => el !== brandId);
    // console.log(brand, "brandbrand")
    setSelBrand([...brand]);
    setSubParam([...brand])
    //   const data = payload1 + "dd"
    //  setCatPayload1 (data)
    //   setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
    if (searchCat) {
      setCatParam(searchCat ? searchCat : '')
    }
    else {
      setCatParam(selCategory ? selCategory : '')
    }

    // setActiveBrand([...brand]);
    // const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
    // setMailPayload(brand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=${[...brand]}&sub_category_uuid=${subCatActive}` : `${defPayload}&brand_uuid=${[...brand]}&sub_category_uuid=${subCatActive}` : `${defPayload}&sub_category_uuid=${subCatActive}&category_uuid=${selCategory}`);
    setTimeout(mailFilter, 1000);
  }

  const mailFilter = () => {
    if (mailList.length > 0) {
      const id = mailList[0]?.deals_type[0]?.uuid;
      const index = subCategoryList.findIndex((el) => el.uuid === id);
    }
  }

  // const VideoPlayer = ({ url }) => {
  //   const embedUrl = `https://www.youtube.com/embed/${url.split('v=')[1]}`;

  //   return (
  //     <div className="video-player-wrapper">
  //       <iframe
  //         width="500"
  //         height="325"
  //         src={embedUrl}
  //         frameBorder="0"
  //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //         allowFullScreen
  //         title="Embedded YouTube Video"
  //       ></iframe>
  //       {/* <p>
  //         Video courtesy of
  //         <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">YouTube</a>.
  //       </p> */}
  //     </div>
  //   );
  // };
  const VideoPlayer = memo(({ url }) => (

    <div className="App">
      {/* <h1>My Video Player</h1> */}
      <ReactPlayer
        url={url}
        controls
        onError={(e) => console.error('Error occurred while playing video:', e)}
      />
    </div>
  ));



  const DefaultAddress = () => {
    setStateData(userDetails?.address?.country);
    if (userDetails?.address?.city != selCity) {
      setSelCity(cityactive === true ? selCity : userDetails?.address?.city);
    }
    if (userDetails?.address?.state != selState) {
      setCountyData(userDetails?.address?.state);
      setSelState(userDetails?.address?.state);
    }
    if (userDetails?.address?.county != selCounty) {
      setCityData(userDetails?.address?.county);
      setSelCounty(userDetails?.address?.county);
    }

    navigate(location.pathname, { replace: true });
    // setShowDrawer(false)
    // setSelCounty(userDetails?.address?.county);
    // setSelState(userDetails?.address?.state);
  }

  const DrawerLocationCardContent = () => (
    <>
      <div css={[classes.locationDetailsContentWrapper]} ref={offerDetailsCardRef}>
        <Dropdown key={uuid()} label={'Country'} component={'autocomplete'} lists={countryList} value={selCountry} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('country', value)} />
        <Dropdown key={uuid()} label={'State'} component={'autocomplete'} lists={stateData} value={selState} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('state', value)} />
        <Dropdown key={uuid()} label={'County'} component={'autocomplete'} lists={countyData} value={selCounty} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('county', value)} />
        <Dropdown key={uuid()} label={'City'} component={'autocomplete'} lists={cityData} value={selCity} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('city', value)} />
      </div>
      {(userDetails?.address?.city !== selCity || userDetails?.address?.county !== selCounty || userDetails?.address?.state !== selState) &&
        <div css={[classes.locationDetailsContentWrapper]} ref={offerDetailsCardRef}>
          <button css={[classes.buttons]} key={uuid()} onClick={() => DefaultAddress()}>Change to Default Address</button>
        </div>
      }
    </>
  );

  const DrawerOfferCardContent = () => (
    <div css={classes.offerDetailsContentWrapper} ref={offerDetailsCardRef}>
      {offerCard?.type === "paid" && ((redeemError === false && isAnimate) || (redeemError === true) || offerCard?.is_seen === 'true') && <IconButton aria-label="back" size="medium" css={classes.backArrow}
        onClick={(e) => { setDrawerContentRoute('search'); setShowDrawer(false); setmailscroll(false); setShowVideo(false) }}
      >
        <ArrowBackIosIcon /> Back
      </IconButton>}
      {/* {offerCard?.type === "paid" && redeemError === true && <IconButton aria-label="back" size="medium" css={classes.backArrow} onClick={(e) => setShowDrawer(false)}>
        <ArrowBackIosIcon /> Back
      </IconButton>} */}
      {offerCard?.type === "regular" && <IconButton aria-label="back" size="medium" css={classes.backArrow} onClick={(e) => { setShowDrawer(false); setmailscroll(false); setShowVideo(false) }}>
        <ArrowBackIosIcon /> Back
      </IconButton>}
      {/* <Modal show={showVideo} style={{ marginTop: "11%" }}> */}
      {/* <div className="video-container">
        <button style={modalStyles.closeButton} onClick={() => handleVideomadel(madelvideo)}>X</button>
        <ReactPlayer url={currentCard?.video_url} />
      </div> */}
      {seconds === 0 ?
        <>
          {currentCard?.video_url ?
            <div className="App">
              <VideoPlayer url={currentCard?.video_url} />
            </div>
            :
            null
          }
        </>
        :
        // <FontAwesomeIcon icon={faSpinner} className={`fa-pulse fa-spin-reverse fa-1x`} color="#1890ff" />
        null
      }

      {/* </Modal> */}
      <div css={classes.timerWrapper}>
        <div style={{ marginLeft: "-100px" }}>
          {currentCard?.type === "paid" && redeemError === false && currentCard?.is_seen === "false" && seconds !== 0 && <div className={classes.progress} style={{ position: 'relative' }}>

            <FontAwesomeIcon icon={faSpinner} className={isAnimate ? 'fa-1x' : `fa-pulse fa-spin-reverse fa-1x`} color="#1890ff" />
            <Typography variant="caption" component="div" color="text.secondary" style={{ position: 'absolute', top: '20px', left: '10px' }}>
              {`${10 - (seconds === 1 ? 10 : seconds)}sec`}
              <div style={{ marginLeft: "10px", marginTop: "-18px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: " hidden" }}>
                <p>{`${(seconds === 1 ? "Please wait, While your mail is being Redeemed ...." : "Please wait, While your mail is being Redeemed ....")}`}</p>
              </div>
            </Typography>
          </div>}
        </div>
      </div>
      <Typography css={classes.subText}>
        {mailscroll === true ? currentCard?.message : currentCard?.message}
      </Typography>
      {
        // console.log(offerCard, "offerCard")
      }
      <div style={carouselContainerStyle}>
        {currentIndex !== 0 && (
          <button onClick={prevImage} style={prevButtonStyle} disabled={currentIndex === 0}>
            {"<"}
          </button>
        )}
        {currentCard?.mime_type === 'text/html' && <div style={{ maxWidth: '98%', margin: '0 auto' }} dangerouslySetInnerHTML={{ __html: mailHTML }} />}
        {/* {currentCard?.mime_type === 'application/pdf' && <Document file={currentCard?.image_url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={numPages} />
        </Document>} */}
        {/* seconds === 0 &&  */}
        {currentCard?.mime_type === 'application/pdf' && <div
          style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            height: '750px',
            width: '100%'
          }}
        >
          <Viewer
            fileUrl={currentCard?.image_url}
          >
          </Viewer>
          {/* <iframe
            src={currentCard?.image_url}
            name="iframe_a"
            title="Iframe Example"
            style={{ width: '100%', height: '100%' }}
            key={currentCard?.image_url}
          ></iframe> */}
        </div>
        }

        {/* {currentCard?.mime_type === 'application/pdf' && <iframe id="inlineFrameExample"
          title="Inline Frame Example"
          width='800'
          height="400"
          src={currentCard?.image_url}>
        </iframe>
        } */}

        {/* {currentCard?.image_url === undefined || currentCard?.image_url === null ? <span>
          No File Available
        </span> :
          <img style={imageStyle1} src={currentCard?.image_url} alt="offer details" />
        } */}
        {/* <div style={imageContainerStyle}>
          {renderContent(currentCard?.image_url)}
          {currentCard ? renderContent(currentCard.image_url) : <span>No File Available</span>}
        </div> */}
        {currentCard?.mime_type === 'text/html' || currentCard?.mime_type === 'application/pdf' ? "" :
          <>
            {currentCard?.image_url === null ? <span>No File Available</span> : <img style={imageStyle1} src={currentCard?.image_url} alt="" />}
          </>
        }
        {currentIndex !== mailList.length - 1 && (
          <button onClick={nextImage} style={nextButtonStyle}>
            {">"}
          </button>
        )}
      </div>
      {/* {(offerCard?.mime_type === 'image/png' || offerCard?.mime_type === 'image/jpeg') && <img css={classes.offerImage} src={offerCard?.image_url} alt="offer details" />} */}
    </div>
  );

  const renderDrawerContent = () => {
    switch (drawerContentRoute) {
      case "search":
        return <DrawerSearchContent classes={classes} brandList={barndList} mailList={mailList} categoryList={categoryList} onFavouriteToggle={onFavouriteToggle} appLogo={appLogo} onClose={toggleDrawer} onCatSelect={onChangeCategoryHandler} onSelect={onBrandSelect} selBrand={selBrand} showDrawer={() => setShowDrawer(false)} drawer={true} />;

      case "location":
        return <DrawerLocationCardContent />;

      case "offerCard":
        return <DrawerOfferCardContent />;
    }
  };

  const onDrawerHandler = (e) => {
    e.preventDefault();
    setShowLeftDrawer(!showLeftDrawer);
    drawerWidth = 0;
    // onChangeCategoryHandler(selCategory ? selCategory : '');
  }

  const onDrawerNotification = (e) => {

    e.preventDefault()
    removeQueryParams()
    setShowRightDrawer(!showRightDrawer)
  }

  const handleClickAway = () => {
    setShowRightDrawer(false);
  };
  // alert(showRightDrawer)
  const Allarray = [
    { name: "All", uuid: '' }
  ]

  const [tabIndexs, setTabindexs] = useState(0)
  const [change, setchange] = useState(false)
  const [checkseen, setCheckseen] = useState(false)
  const activefunct = (data) => {
    // alert('ok mahesh')
    if (data === false) {
      setCheckseen(true)
      setseen('no')
      const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
      setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=${selBrand}&sub_category_uuid=${subCatActive}&type=&search=&pagenum=&limit=&seen=no` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${subCatActive}&type=&search=&pagenum=&limit=&seen=no` : `${defPayload}&sub_category_uuid=${subCatActive}&category_uuid=${selCategory}&search=&pagenum=&limit=&seen=no`);
    }
    else {
      setCheckseen(false)
      setseen('')
      const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
      setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=${selBrand}&sub_category_uuid=${subCatActive}&seen=''` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${subCatActive}&seen=''` : `${defPayload}&sub_category_uuid=${subCatActive}&category_uuid=${selCategory}&search=&seen=''`);
    }
    // setseen()
  }


  const initialItemsToShow = 5;
  const [notifications, setNotifications] = useState()
  const [itemsToShow, setItemsToShow] = useState(initialItemsToShow);

  const navigationView = (link) => {
    navigate(link)
    // window.location.reload(false)
  }
  const NotificationUpdateseen = (notifiid) => {
    const payload = {
      "notify_uuid": notifiid
    }
    axios.put(`${baseUrl}/notification/update/seen`, payload, {
      headers: {
        "x-auth-token": userdata && userdata?.token
      }
    })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const contentStyle = {
    // background: "salmon",
    // width: "100%",
    padding: 20,
    color: "white"
  };
  const image = {
    display: "block",
    maxWidth: "60%",
    // maxHeight: "70%",
    marginLeft: "25%",
    borderRadius: 4
  };
  const imageStyle = {
    cursor: 'pointer', // Ensure the image has a pointer cursor
    // Add other styles as needed
  };

  const stories2 = Popupaddsdata1?.map((item, index) => ({
    content: ({ action, isPaused }) => (
      <div style={imageStyle} key={index} >

        <img style={image} src={item?.image} alt={`Story ${index}`} />
      </div >
    )
  }));
  const handleStoryStart = (data1, index) => {
    // if (Popupaddsdata) {

    const data = {
      id: userdata?.uuid,
      indexId: index
    }
    setPayloads(data)
    setPayloadsPopupseen({
      user_uuid: userdata?.uuid
    });
    setPayloadsPopupseenuser({
      user_uuid: userdata?.uuid,
      popup_uuid: data1
    });
    // }
  }
  const handleClick1 = (index) => {
    const data = {
      id: userdata?.uuid,
      indexId: index
    }
    setPayloads(data)
    setOpen1(false)

  }

  const [subscribeshow, setSubscribeShow] = useState(false);
  const unSubscribeMail = (dataSub) => {
    const payload = {
      "mail_uuid": dataSub?.uuid,
      "user_uuid": userDetails?.uuid
    }
    axios.put(`${baseUrl}/mail/unsubscribe`, payload, {
      headers: {
        "x-auth-token": userDetails?.token
      }
    })
      .then((res) => {
        setSubscribeShow(false);
        setmailSubscribeUpdate(mailSubscribeUpdate ? false : true);
        setTimeout(() => {
          const data = payload1 + String(mailSubscribeUpdate)
          setCatPayload1(data)
        }, 1000)

        setTimeout(() => {
          const defUrl = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&sub_category_uuid=${subCatActive}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
          setMailPayload(selBrand?.length > 0 ? `${defUrl}&brand_uuid=${selBrand}&category_uuid=${selCategory}&seen=${seen}` : selCategory ? `${defUrl}&category_uuid=${selCategory}&search=&pagenum=&limit=&seen=${seen}` : `${defUrl}`)
        }, 1000)
        // const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
        // setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=${selBrand}&sub_category_uuid=${subCatActive}&seen=''` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${subCatActive}&seen=''` : `${defPayload}&sub_category_uuid=${subCatActive}&category_uuid=${selCategory}&search=&seen=${checkseen ? 'no' : ''}`);
      })
      .catch((err) => {
        console.log(err)
      })
  }


  return (
    <>

      {modeldata?.data && modeldata.data.length > 0 && (
        <Modal show={show && openShow === 0} style={{ width: '100%' }}>
          {modeldata?.data?.map((item) => {
            return <div key="" style={modalStyles.overlay}>
              <div style={modalStyles.content}>
                <button style={modalStyles.Closebutton} onClick={() => handleClose(item?.uuid)}>X</button>
                <div style={modalStyles.body} onClick={() => goToNextAd(item?.uuid)}>
                  <img src={item?.image} alt="MealBox Offer" style={modalStyles.image} />
                </div>
              </div>
            </div>
          })}
        </Modal>
      )}

      <Modal show={open1} style={{ marginTop: "5%", marginLeft: "19%" }} onHide={() => setOpen1(false)}>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {Popupaddsdata1?.length > 0 ? (
            <div className="stories-container" >
              <Stories
                loop
                keyboardNavigation
                defaultInterval={10000}
                stories={stories2}
                width={1000} // Set the width
                height={550}
                // onClick={setPayloads(userdata?.uuid)}
                onStoryEnd={(s, st) => handleClick1(s)}
                // onAllStoriesEnd={(s, st) => console.log("all stories ended", s, st)}
                onStoryStart={(s, st) => handleStoryStart(Popupaddsdata1[s].uuid, s)}
                onAllStoriesEnd={(s) => handleClick1(s)}

              />
            </div>
          ) : (
            <div style={{ marginRight: "40%" }}>No Popup Adds</div>
          )}
        </div>

      </Modal>




      <div css={classes.pageContainer}>
        <AppBar navItems={useGetNavItems({ handleNavLinksClick, selCountry, selState, selCity, setSelCity, setcityactive, selCounty, selCategory, cityData, selBrand, subCatActive, onDrawerNotification, handleClickOpen1, Popupaddsdata })} onDrawerHandler={onDrawerHandler} drawerWidth={drawerWidth} />
        <Box css={classes.flex}>
          {categoryList?.length > 0 && <Drawer
            anchor={'left'}
            variant="persistent"
            open={showLeftDrawer}
            css={[classes.sideDrawer, {
              width: drawerWidth,
              [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', marginTop: 80 },
            }]}
            onClose={(e) => setShowLeftDrawer(!showLeftDrawer)}
          >
            {/* {Allarray.map((all, index) => {
            return (<ListItem key={index} component="div" disablePadding>
              <ListItemButton selected={selCategory === all?.uuid || !selCategory ? true : false} key={index} onClick={(e) => { onChangeCategoryHandler(all.uuid); setShowLeftDrawer(!showLeftDrawer) }} sx={{ '.Mui-selected': { background: '#efefef' } }}>
                <ListItemText primary={`${all.name}`} />
              </ListItemButton>
            </ListItem>
            )
           })} */}
            {categoryList.map((list, i) => {
              return (<ListItem key={i} component="div" disablePadding>
                <ListItemButton selected={selCategory === list.uuid ? true : false} key={i} onClick={(e) => { onChangeCategoryHandler(list.uuid); }} sx={{ '.Mui-selected': { background: '#efefef' } }}>
                  <ListItemText primary={`${list.name}`} /><span >{`${list.mails_count === 0 || list.mails_count === undefined ? "" : list.mails_count}`}</span>
                </ListItemButton>
              </ListItem>)
            })}
            {/* <a href="https://stage-bingo.money-mails.com/" target="_blank" ><button>Bingo</button></a> */}

          </Drawer>}

          <Box
            component="main"
            sx={{ flexGrow: 1, marginLeft: showLeftDrawer ? 0 : `-${drawerWidth}px` }}
          >
            {data.length > 0 && <ScrollingCarousel className="scrollingCarousal">
              {data?.map((item) => <img key={uuid()} src={`${item?.image}`} css={classes.offerImg} alt="scrollimage" />)}

            </ScrollingCarousel>}
            {/* <div css={classes.searchWraper}>
        {categoryList && <div css={classes.searchLayer}><Dropdown key={uuid()} label={'select category'} component={'autocomplete'} lists={categoryList} value={selCategory} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeCategoryHandler('category', value)} /></div>}
      </div> */}
            <div css={classes.brandCardsWrapper}>
              {isBrandLoading === true && <FontAwesomeIcon icon={faSpinner} className="fa-pulse fa-spin-reverse fa-4x" color="#1890ff" />}

              {isBrandLoading === false && barndList?.length > 0 && barndList?.map((item, i) => {
                const BrandIcn = brandSvgs[i % brandSvgs.length];
                if (!item?.is_active) return (<></>);
                return (
                  <div css={classes.brandCard} style={{ cursor: 'pointer' }}>
                    <BrandIconCard
                      key={uuid()}
                      rounded
                      isActive={selBrand?.indexOf(item?.uuid) !== -1 || item.is_highlight === 'true' ? true : false}
                      onSelect={(e) => onBrandSelect(e, item?.uuid, item)}
                      isFavourite={item?.is_faviourate === 'true' ? true : false}
                      onFavouriteToggle={(e) => onFavouriteToggle(e, item?.uuid)}
                      size={100}
                      maxSize={100}
                      src={item?.logo_url}
                      src1={item}
                      onClick={() => {
                        setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 16}&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${item.uuid}`);
                        setSubCatPayload(`country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&search=01`)
                        setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
                      }}
                    >
                      <div css={classes.imageView}>
                        {item?.logo_url && <img src={`${item?.logo_url}`} width="60%" height={'auto'} style={{ borderRadius: 19 }} />}
                        {item?.icon && <BrandIcn width="95%" height="95%" />}
                        <div>{item?.mails_count === 0 ? "" : item?.mails_count}</div>
                      </div>
                      {/* <div style={{ borderRadius: 19 }}>{item?.mails_count}</div> */}
                    </BrandIconCard>
                  </div>
                );
              })}
              {brandCount > 16 && <div css={classes.moreBrand}>
                <IconButton aria-label="more" onClick={(e) => navigate(`/moreBrands?brand_uuid=${selBrand}`, {
                  state: {
                    selCountry: selCountry,
                    selState: selState,
                    selCity: selCity,
                    selCounty: selCounty,
                    selCategory: selCategory,
                    selBrand: selBrand
                  }
                })}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </div>}
              {brandCount === 0 && isBrandLoading === false && <div css={[classes.noResult]}><p>{COMING_SOON}</p></div>}
            </div>
            <div css={classes.tabsWrapper}>
              <TabView
                tabs={subCategoryList}
                onTabChane={onTabChane}
                subUuid={subCatActive}
                setTabindexs={setTabindexs}
                tabIndex={tabIndexs}
                renderActiveTabContent={() => (
                  <>
                    &nbsp; &nbsp;
                    <div>
                      {/* <p>{checkseen === true ? "Unseenmails" :checkseen === false ? "All mails" : ""}</p> */}
                      {/* <a href="#" data-toggle="tooltip" title="Hooray!">Hover over me</a> */}
                      {/* <Tooltip title="Unseen mails"> */}
                      {/* <ToggleButton
                      inactiveLabel={"All"}
                      activeLabel={"UnSeen"}
                      width={100}
                      value={checkseen}
                      onToggle={() => activefunct(checkseen)}
                    /> */}
                      {/* </Tooltip> */}
                      {/* <BootstrapSwitchButton checked={true} width={100} /> */}
                      <BootstrapSwitchButton
                        width={100}
                        checked={checkseen}
                        onlabel='Un seen'
                        offlabel='All'
                        value={checkseen}
                        onChange={() => activefunct(checkseen)}
                      />
                    </div>
                    <div css={classes.offerCardsWrapper}>
                      {isMailLoading === true && <FontAwesomeIcon icon={faSpinner} className="fa-pulse fa-spin-reverse fa-4x" color="#1890ff" />}
                      {isMailLoading === false && mailList?.length > 0 && mailList.map((_item, idx) => {
                        if (!_item?.is_active) return (<></>);
                        let j = idx % 4;
                        return (<OfferCard
                          key={uuid()}
                          containerStyles={{
                            backgroundColor: colors[j]
                          }}
                          isRedeemed={_item?.is_seen === 'true' ? true : false}
                          item={_item}
                          UnsubscribeMailClick={() => unSubscribeMail(_item)}
                          show={subscribeshow}
                          setShow={(e1) => setSubscribeShow(e1)}
                          showUnSuscribe={true}
                          // size={200}
                          isFavourite={_item?.is_favourite === 'true' ? true : false}
                          onFavouriteMailToggle={(e) => onFavouriteMailToggle(e, _item?.uuid, _item)}
                          isLocation={() => setLocationCheck(false)}
                          // selCountry = {selCountry}
                          // selState = {selState}
                          // selCounty = {selCounty}
                          // selCity = {selCity}
                          message={_item?.message}
                          onClick={() => {
                            // if (_item?.is_seen === 'true') return;
                            // onChangeCategoryHandler(selCategory)
                            // setTimeout(() => {
                            setredeemmsgs(_item?.is_seen)
                            // }, 2500);

                            setTimeout(() => {
                              setredeemmsgs(false)
                            }, 4500);
                            const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&sub_category_uuid=&category_uuid=${selCategory}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`
                            setMailPayload(`${defPayload}&brand_uuid=&search=&pagenum=&limit=`)

                            // setmailvideomadel(true)
                            setmadelvideo(_item)
                            setShowVideo(true);
                            setSelectedOfferIndex(idx);
                            // setTimeout(() => {
                            handleNavLinksClick("offerCard", _item);
                            // }, 3500);

                            setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
                            setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 16}&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);
                            // setSubCatPayload(`country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&search=`)

                            // if (!redeemedOfferIndexes.includes(idx)) {
                            //   setSelectedOfferIndex(idx);
                            //   handleNavLinksClick("offerCard", _item);
                            // }
                          }}
                        />
                        )
                      })}

                      {mailCount > 15 && <div css={[classes.moreMail]}>
                        <IconButton aria-label="more" onClick={(e) => navigate(`/moreMails?country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&sub_category_uuid=${subCatActive}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}&selCategory=${selCategory}&brand_uuid=${selBrand}&type=filter`, {
                          state: {
                            selCountry: selCountry,
                            selState: selState,
                            selCity: selCity,
                            selCounty: selCounty,
                            selCategory: selCategory,
                            selBrand: selBrand
                          }
                        })}>
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </div>}
                      {mailCount === 0 && isMailLoading === false && <div css={[classes.noResult]}><p>{NO_RESULT}</p></div>}
                    </div>

                  </>
                )}
              />
              {/* <ToggleButton
              value={checkseen}
              onToggle={() => activefunct(checkseen)}

            /> */}
            </div>
          </Box>
        </Box>
        <div>
          {/* <AppFooter /> */}
          <Footer1 />
        </div>

        <Drawer
          anchor="top"
          open={showDrawer}
          onClose={toggleDrawer}
          PaperProps={{
            sx: classes.drawer,
          }}
          sx={[classes.drawerContainer, { zIndex: 1277 }]}
        >
          {renderDrawerContent()}
        </Drawer>
        {isRedeem && <Snackbar message={redeemMessage} isOpen={isRedeem} severity={redeemError ? 'error' : 'success'} />}
        {locationCheck && <Snackbar message={"Favourite Not Possible"} isOpen={locationCheck} severity={'error'} />}
        {redeemmsgs && currentCard?.type === "paid" && <Snackbar message={redeemmsg} isOpen={redeemmsgs} severity={'success'} />}
        {/* redeemmsgs */}
        {showRightDrawer &&
          <ClickAwayListener onClickAway={handleClickAway}>

            <Drawer

              anchor={'right'}
              variant="persistent"
              open={showRightDrawer}
              // onClose={toggleDrawer(anchor, false)}
              ModalProps={{
                onBackdropClick: (e) => setShowRightDrawer(!showRightDrawer)
              }}
              css={[classes.sideDrawer, {
                width: drawerWidth,
                // style={{ width: "326px", marginTop: "117px" }}
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', marginTop: 80, width: 330, marginTop: 117, height: 410, overflowX: "hidden" },
              }]}
              onClose={(e) => setShowRightDrawer(!showRightDrawer)}

            >

              <h4 style={{ marginLeft: "41px", marginTop: "28px" }}>Notifications</h4>
              {noticationdata?.slice(0, itemsToShow).map((items) => {
                return (
                  <>
                    {/* onClick={(event) => {navigationView(item.link);NotificationUpdateseen(item?.uuid)}} */}
                    {items?.is_seen === true ?
                      <div className="container" style={{ marginLeft: "11px", cursor: 'pointer' }} onClick={(event) => { setShowRightDrawer(false); navigationView(items.link); NotificationUpdateseen(items?.uuid) }}>
                        <h6 ><span class="dot" style={{ height: "10px", width: "10px", backgroundColor: "green", borderRadius: "50%", display: "inline-block", marginRight: "10px" }}></span>{items.title}</h6>
                        <p style={{ marginLeft: "20px", width: "200px" }}>{items.message}</p>
                      </div>
                      :
                      <div className="container" style={{ marginLeft: "11px", cursor: 'pointer' }} onClick={(event) => { setShowRightDrawer(false); navigationView(items.link); NotificationUpdateseen(items?.uuid) }}>
                        <h6 ><span class="dot" style={{ height: "10px", width: "10px", backgroundColor: "red", borderRadius: "50%", display: "inline-block", marginRight: "10px" }}></span>{items.title}</h6>
                        <p style={{ marginLeft: "20px", width: "200px" }}>{items.message}</p>
                      </div>}
                  </>
                )
              })
              }
              <NavLink to='/Notifications' style={{ color: "green", marginLeft: "76px" }}>
                <u>View More</u>
              </NavLink>
            </Drawer>
          </ClickAwayListener>
        }
      </div>


    </>
  );
};

export default memo(Home);
